import { Column } from "@material-table/core";

export interface ITiposDeDocumento {
  id: string;
  nombre: string;
  moduloId: number;
}

export interface IAuthContext extends IInfoLogin {
  globalMonedas?: IAGlobalMoneda[];
  tokenActual?: string;
  roleId: number;
  roleNombre: string;
  utilizaCuadreCaja: boolean;
  almacenGrupoId: number;
  almacenGrupoNombre: string;
  cuadreCajaId: number;
  cuadreCajaCodigo: number;
  cuadreCajaMontoInicio: number;
  companiaSeleccionada: ICompania;
  ImagenesDefault: {
    rutaNoImage: string;
    rutaIconoClickteck: string;
    rutaAvatarDefault: string;
    rutaImagenCompaniaDefault: string;
  };
  onCambioColorCompania: ({
    r,
    g,
    b,
    a,
  }: {
    r: number;
    g: number;
    b: number;
    a: number;
  }) => void;
  onActualizarLoginState: () => void;
  onLogOut: () => void;
  accesos: IUsuarioAcceso[];
}

export const AuthContextDefault: IAuthContext = {
  id: 0,
  username: "",
  email: "",
  idleTime: 30,
  tiposDeDocumento: [],
  companiasConAcceso: [],
  roleId: 0,
  roleNombre: "operador",
  utilizaCuadreCaja: false,
  almacenGrupoId: 0,
  almacenGrupoNombre: "",
  cuadreCajaId: 0,
  cuadreCajaCodigo: 0,
  cuadreCajaMontoInicio: 0,
  tokens: [],
  tipoComprobante: [],
  tipoBienServicio: [],
  tipoIngreso: [],
  medioPago: [],
  tipoItbis: [],
  tipoRetencionIsr: [],
  tipoRetencionItbis: [],
  tipoAnulacion: [],
  configuraciones607: [],
  dashBoardTipoGrafico: [],
  dashBoardOpcionGrafico: [],
  frecuenciaPago: [],
  RRHHAcciones: [],
  RRHHSupAFP: [],
  RRHHSupARS: [],
  gruposSangrineo: [],
  companiaSeleccionada: {
    id: 0,
    nombre: "",
    cantidadUsuarios: 0,
    utilizaCentroCosto: false,
    selectivoPor: 0,
    propinaPor: 0,
    usaEcf: false,
    r: 0,
    g: 0,
    b: 0,
    a: 0,
    isInactivo: false,
    ActivoFijoCategorias: [],
    ActivoFijoDepartamentos: [],
    ActivoFijoGrupos: [],
    monedas: [],
    almacenes: [],
    almacenesGrupos: [],
    inventarioConfiguracion: {
      companiaId: 0,
      tipoImpresionEntrada: 0,
      tipoImpresionSalida: 0,
      tamanoLetraEntrada: 0,
      tamanoLetraSalida: 0,
    },
    inventarioLiquidacionAduanalTipoGasto: [],
    inventarioProyectos: [],
    inventarioProductoTipoInventario: [],
    inventarioProductoGrupo: [],
    inventarioProductoSubGrupo: [],
    centroCostos: [],
    centroCostoProyectos: [],
    centroCostoSubProyectos: [],
    bancos: [],
    CxCConfiguracion: {
      companiaId: 0,
      cotizacionDiasValidez: 0,
      facturaImprimirGrabar: true,
      tipoImpresionFactura: 0,
      tipoImpresionRecibo: 0,
      tipoImpresionND: 0,
      tipoImpresionNC: 0,
      tamanoLetraNC: 0,
      tamanoLetraND: 0,
      modoImpresionDespacho: 0,
      notaPieFactura: "",
      notaPieCotizacion: "",
      comisionVendedor: [],
      comisionVendedorGov: [],
      requerirAutorizacionDebajoMinVenta: false,
      porcentajeMinimoVenta: 0,
      porcentajeSugeridoVenta: 0,
      notifEstadoLunes: false,
      notifEstadoMartes: false,
      notifEstadoMiercoles: false,
      notifEstadoJueves: false,
      notifEstadoViernes: false,
      notifEstadoSabado: false,
      notifEstadoDomingo: false,
      notifEstadoDiasAVencer: 0,
      notifEstadoHora: 0,
      notifEstadoCabeza: "",
      notifEstadoPie: "",
      logoCompaniaWidth: 125,
      logoCompaniaHeight: 50,
      isImprimirInfoCompania: true,
      isImprimirInfoCompaniaRecibo: true,
      isImprimirInfoUsuarioFecha: true,
      margenSuperior: 0,
      margenSuperiorRecibo: 0,
      margenSuperiorNC: 0,
      margenSuperiorND: 0,
      tamanoLetraFactura: 0,
      tamanoLetraRecibo: 0,
      isImpresionBlancoNegro: false,
      mostrarPrecioItbisFacturacion: false,
    },
    CxCClienteGrupos: [],
    CxCRestaurantMesas: [],
    CxPBuscadores: [],
    CxPConfiguracion: {
      companiaId: 0,
      tipoImpresionOrdenCompra: 0,
      isImpresionBlancoNegro: false,
    },
    CxPPesadores: [],
    CxPSuplidorGrupos: [],
    CxCVendedores: [],
    CxCClientes: [],
    contabilidadConfiguracion: {
      id: 0,
      companiaId: 0,
      bancoSaldarRetencionesEnPago: false,
      isRegistroAbierto: false,
    },
    comprobantes: [],
    tipoComprobantes: [],
    modulos: [],
    financiamientoConfiguracion: {
      companiaId: 0,
      frecuenciaId: 0,
      frecuencia: 0,
      frecuenciaNombre: "",
      porMora: 0,
      diasLimitePago: 0,
      sumarGastos: false,
    },
    visitaConfiguracion: {
      companiaId: 0,
      isImprimir: false,
      tipoPapel: "",
    },
    tallerMecanicaConfiguracion: {
      companiaId: 0,
      almacenId: 0,
      mantenimientoDias: 0,
      mantenimientoConsultaDefaultDias: 0,
      mantenimientoAvisoClienteDias: 0,
      mantenimientoAvisoClienteHora: 0,
      mantenimientoAvisoClienteActivo: false,
      mantenimientoAvisoClienteHeader: "",
      mantenimientoAvisoClienteFooter: "",
      almacenNombre: "",
      almacenGrupoNombre: "",
    },
    bancoChequeConfiguracionImpresion: [
      {
        companiaId: 0,
        cuentaId: 0,
        tamanoPapel: 0,
        posicionConcepto: 0,
        destinatarioX: 0,
        destinatarioY: 0,
        montoX: 0,
        montoY: 0,
        montoLetraX: 0,
        montoLetraY: 0,
        numeroChequeX: 0,
        numeroChequeY: 0,
        diaX: 0,
        diaY: 0,
        mesX: 0,
        mesY: 0,
        anoX: 0,
        anoY: 0,
        conceptoFechaX: 0,
        conceptoFechaY: 0,
        conceptoObservacionX: 0,
        conceptoObservacionY: 0,
        conceptoMontoX: 0,
        conceptoMontoY: 0,
        cuentaX: 0,
        cuentaY: 0,
        cuentaNombreX: 0,
        cuentaNombreY: 0,
        cuentaDebitoX: 0,
        cuentaDebitoY: 0,
        cuentaCreditoX: 0,
        cuentaCreditoY: 0,
        tamanoLetra: 12,
        cuentaNombre: "",
        cuentaCuentaBancaria: "",
      },
    ],
    nominaConfiguracion: {
      id: 0,
      companiaId: 0,
      codigoNomina: 0,
      riesgoLaboral: 0,
      tipoNomina: 0,
      tipoCalculoIRS: 0,
      tipoCalculoAFP: 0,
      tipoCalculoARS: 0,
      diasTrabajoMes: 0,
    },
    RRHHPosiciones: [],
    nominaDepartamentos: [],
  },
  ImagenesDefault: {
    rutaNoImage: "",
    rutaIconoClickteck: "",
    rutaAvatarDefault: "",
    rutaImagenCompaniaDefault: "",
  },
  onCambioColorCompania: ({ r = 0, g = 0, b = 0, a = 0 }) => {},
  onActualizarLoginState: () => {},
  onLogOut: () => {},
  accesos: [
    {
      id: 0,
      usuarioId: 0,
      accesoId: 0,
      companiaId: 0,
      accesoNombre: "",
      moduloId: 0,
      moduloNombre: "",
    },
  ],
};

export interface IInfoLogin {
  id: number;
  realm?: string;
  username: string;
  email: string;
  avatar?: string;
  idleTime: number;
  imagen?: string;
  tiposDeDocumento: ITiposDeDocumento[];
  companiasConAcceso: ICompaniasConAcceso[];
  tokens: IToken[];
  tipoComprobante: IAGlobalTipoComprobante[];
  tipoBienServicio: IAGlobalTipoBienServicio[];
  tipoIngreso: IAGlobalTipoIngreso[];
  medioPago: IAGlobalMedioPago[];
  tipoItbis: { id: number; nombre: string; porcentaje: number }[];
  tipoRetencionIsr: IAGlobalTipoRetencionIsr[];
  tipoRetencionItbis: IAGlobalTipoRetencionItbis[];
  tipoAnulacion: IAGlobalTipoAnulacion[];
  configuraciones607: IAGlobal607Configuraciones[];
  dashBoardTipoGrafico: IAGlobalDashBoardTipoGrafico[];
  dashBoardOpcionGrafico: IAGlobalDashBoardOpcionGrafico[];
  frecuenciaPago: IAGlobalFinanciamientoFrecuenciaPago[];
  RRHHAcciones: IAGlobalRRHHAcciones[];
  RRHHSupAFP: IAGlobalRRHHSupAFP[];
  RRHHSupARS: IAGlobalRRHHSupARS[];
  gruposSangrineo: { id: number; nombre: string }[];
}

export interface IToken {
  id: string;
  ttl: number;
  scopes?: string;
  created: string;
  userId: number;
}

export interface ICompaniasConAcceso {
  companiaId: number;
  companiaNombre: string;
  companiaIcono?: string;
  utilizaCuadreCaja: boolean;
  almacenGrupoId: number;
  almacenGrupoNombre?: string;
  isInactivo: boolean;
}

export interface IActivoFijoActivo {
  id?: number;
  companiaId: number;
  categoriaId: number;
  departamentoId: number;
  grupoId?: number;
  responsableId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  referencia: string;
  nombre: string;
  codigoBarra?: string;
  marca?: string;
  modelo?: string;
  serie?: string;
  factura?: string;
  costo: number;
  suplidor?: number;
  fechaCompra?: string;
  foto?: string;
  fechaInicioDepreciacion: string;
  depreciacionAcumuladaInicial: number;
  valorLibro: number;
  valorResidual: number;
  depreciacionMensual: number;
  depreciacionAcumulada: number;
  isInactivo: boolean;
  categoriaNombre: string;
  categoriaPorcentaje: number;
  categoriaCuentaId: number;
  categoriaCuenta: string;
  categoriaCuentaNombre: string;
  departamentoNombre: string;
  departamentoCuentaId: number;
  departamentoCuenta: string;
  departamentoCuentaNombre: string;
  grupoNombre?: string;
  responsableNombre?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  depreciaciones: IActivoFijoDepreciacionDetalle[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IActivoFijoCategoria {
  id?: number;
  companiaId: number;
  categoriaId: number;
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  categoriaNombre: string;
  categoriaPorcentaje: number;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IActivoFijoDepartamento {
  id?: number;
  companiaId: number;
  nombre: string;
  cuentaId: number;
  isInactivo: boolean;
  cuenta: string;
  cuentaNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IActivoFijoDepreciacion {
  id?: number;
  companiaId: number;
  codigo?: number;
  fecha: string;
  detalles: IActivoFijoDepreciacionDetalle[];
  cuentas: IActivoFijoDepreciacionCuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IActivoFijoDepreciacionCuenta {
  id?: number;
  depreciacionId?: number;
  cuentaId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  debito: number;
  credito: number;
  cuenta: string;
  cuentaNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  eliminar?: boolean;
}

export interface IActivoFijoDepreciacionDetalle {
  id?: number;
  depreciacionId?: number;
  activoId: number;
  depreciacionAcumInicio: number;
  monto: number;
  depreciacionAcumFin: number;
  valorLibro: number;
  activoReferencia: string;
  activoNombre: string;
  activoFechaCompra?: string;
  activoCosto: number;
  categoriaCuentaId: number;
  categoriaCuenta: string;
  categoriaCuentaNombre: string;
  departamentoCuentaId: number;
  departamentoCuenta: string;
  departamentoCuentaNombre: string;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  eliminar?: boolean;
}

export interface IActivoFijoGrupo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IActivoFijoResponsable {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IAGlobalEvento {
  id?: Number;
  fecha?: string;
  tipoDocumento?: string;
  documentoId?: number;
  companiaId?: number;
  documento?: string;
  estadoAnterior?: string;
  estadoActual?: string;
  usuario?: string;
}

export interface IAGlobalFinanciamientoFrecuenciaPago {
  id: number;
  nombre: string;
  frecuencia: number;
}

export interface IAGlobalMedioPago {
  id: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalMoneda {
  id: number;
  codigo: string;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobal607Configuraciones {
  id: number;
  fechaInicio: string;
  montoMaxConsumos: number;
}

export interface IAGlobalDashBoardOpcionGrafico {
  id: number;
  tipoGraficoId: number;
  accesoId: number;
  rutaAPI: string;
  nombre: string;
}

export interface IAGlobalDashBoardTipoGrafico {
  id: number;
  nombre: string;
}

export interface IAGlobalRRHHAcciones {
  id: number;
  clasificacionId: number;
  nombre: string;
  clasificacionNombre: string;
}

export interface IAGlobalRRHHSupAFP {
  id: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalRRHHSupARS {
  id: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalTipoAnulacion {
  id: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalTipoBienServicio {
  id: number;
  codigo606: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalTipoComprobante {
  id: number;
  nombre: string;
  moduloId: number;
  grupo: number;
}

export interface IAGlobalTipoIngreso {
  id: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IAGlobalTipoRetencionItbis {
  id: number;
  nombre: string;
  porcentaje: number;
  isInactivo: boolean;
}

export interface IAGlobalTipoRetencionIsr {
  id: number;
  codigo606: number;
  nombre: string;
  porcentaje: number;
  isInactivo: boolean;
}

export interface IBancoCargo {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId: number;
  monedaId: number;
  cuentaId: number;
  conciliacionId?: number;
  tipoBienServicioId?: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  ncf?: string;
  observacion: string;
  total: number;
  total606: number;
  totalTasa: number;
  isAnulado: boolean;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  bancoCuentaRnc: string;
  monedaNombre: string;
  cuenta: string;
  cuentaNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  tipoBienServicioNombre?: string;
  tipoBienServicioCodigo606?: number;
  conciliacionCodigo?: number;
  conciliacionFecha?: string;
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoCheque {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId: number;
  suplidorId?: number;
  cuentaId?: number;
  conciliacionId?: number;
  destinatario?: string;
  monedaId: number;
  solicitudId?: number;
  prestamoId?: number;
  nominaDetalleId?: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  observacion: string;
  monto: number;
  montoRetItbis: number;
  montoRetIsr: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  isPagoOtraMoneda: boolean;
  isPagado: boolean;
  isAnulado: boolean;
  isSaldarRetencionesEnPago: boolean;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  monedaAplicarId: number;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  suplidorRncCedula?: string;
  suplidorNombre?: string;
  suplidorDireccion?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuenta?: string;
  cuentaNombre?: string;
  solicitudCodigo?: number;
  conciliacionCodigo?: number;
  conciliacionFecha?: string;
  prestamoCodigo?: number;
  prestamoMontoGastosCierre?: number;
  prestamoMontoGastosLegales?: number;
  cuentas: ICuenta[];
  pagosFT: IBancoChequePagoFT[];
  pagosND: IBancoChequePagoND[];
  pagosRG: IBancoChequePagoRG[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoChequeConfiguracionImpresion {
  id?: number;
  companiaId: number;
  cuentaId: number;
  tamanoPapel: number;
  posicionConcepto: number;
  destinatarioX: number;
  destinatarioY: number;
  montoX: number;
  montoY: number;
  montoLetraX: number;
  montoLetraY: number;
  numeroChequeX: number;
  numeroChequeY: number;
  diaX: number;
  diaY: number;
  mesX: number;
  mesY: number;
  anoX: number;
  anoY: number;
  conceptoFechaX: number;
  conceptoFechaY: number;
  conceptoObservacionX: number;
  conceptoObservacionY: number;
  conceptoMontoX: number;
  conceptoMontoY: number;
  cuentaX: number;
  cuentaY: number;
  cuentaNombreX: number;
  cuentaNombreY: number;
  cuentaDebitoX: number;
  cuentaDebitoY: number;
  cuentaCreditoX: number;
  cuentaCreditoY: number;
  tamanoLetra: number;
  cuentaNombre: string;
  cuentaCuentaBancaria: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoChequePagoFT {
  id?: number;
  chequeId?: number;
  facturaId: number;
  retencionItbisId?: number;
  montoRetItbis: number;
  retencionIsrId?: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  facturaCodigo: string;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  facturaNetoBien: number;
  facturaNetoServicio: number;
  facturaItbis: number;
  retencionItbisNombre?: string;
  retencionItbisPorcentaje?: number;
  retencionIsrNombre?: string;
  retencionIsrPorcentaje?: number;
  eliminar?: boolean;
}

export interface IBancoChequePagoND {
  id?: number;
  chequeId?: number;
  notaDebitoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBancoChequePagoRG {
  id?: number;
  chequeId?: number;
  reembolsoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  reembolsoCodigo: number;
  reembolsoFecha: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBancoConciliacion {
  id?: number;
  companiaId: number;
  codigo?: number;
  bancoCuentaId: number;
  fecha: string;
  balanceInicial: number;
  balanceFinal: number;
  ckConciliado: number;
  ckTransito: number;
  tbsConciliado: number;
  tbsTransito: number;
  tbeConciliado: number;
  tbeTransito: number;
  cbConciliado: number;
  cbTransito: number;
  dpConciliado: number;
  dpTransito: number;
  crConciliado: number;
  crTransito: number;
  balanceContable: number;
  balanceBanco: number;
  balanceTransito: number;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  cuentaId: number;
  fechaConciliacionAnterior?: Date;
  documentos: IBancoConciliacionDocumento[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoConciliacionDocumento {
  id?: number;
  conciliacionId?: number;
  fecha: string;
  tipo: string;
  documentoId: number;
  monto: number;
  isConciliado: boolean;
  eliminar?: boolean;
}

export interface IBancoCredito {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId: number;
  monedaId: number;
  cuentaId: number;
  conciliacionId?: number;
  tipoBienServicioId?: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  ncf?: string;
  ncfAfectado?: string;
  observacion: string;
  total: number;
  total606: number;
  totalTasa: number;
  isAnulado: boolean;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  bancoCuentaRnc: string;
  monedaNombre: string;
  cuenta: string;
  cuentaNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  tipoBienServicioNombre?: string;
  tipoBienServicioCodigo606?: number;
  conciliacionCodigo?: number;
  conciliacionFecha?: string;
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoCuenta {
  id?: number;
  companiaId: number;
  nombre: string;
  cuentaBancaria: string;
  monedaId: number;
  cuentaId: number;
  cuentaPrimaId: number;
  rnc: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  direccion?: string;
  noPermiteSobregiro: boolean;
  isInactivo: boolean;
  cuenta: string;
  cuentaNombre: string;
  monedaNombre: string;
  cuentaPrima: string;
  cuentaPrimaNombre: string;
  balances: IBancoCuentaBalance[];
}

export interface IBancoCuentaBalance {
  id: number;
  bancoCuentaId: number;
  monedaId: number;
  balance: number;
  monedaNombre: string;
}

export interface IBancoDeposito {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId?: number;
  monedaId?: number;
  conciliacionId?: number;
  conciliacionCodigo?: number;
  conciliacionFecha?: string;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  observacion?: string;
  total: number;
  totalTasa: number;
  isAnulado: boolean;
  bancoCuentaCuentaBancaria?: string;
  bancoCuentaNombre?: string;
  monedaNombre?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuentas: ICuenta[];
  recibos: IBancoDepositoRecibo[];
  recibosManual: IBancoDepositoReciboManual[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoDepositoRecibo {
  id?: number;
  depositoId?: number;
  reciboId?: number;
  monto: number;
  tasa: number;
  montoDepositar: number;
  reciboCodigo: number;
  reciboFecha: string;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  monedaId: number;
  monedaNombre: string;
  eliminar: boolean;
}

export interface IBancoDepositoReciboManual {
  id?: number;
  depositoId?: number;
  reciboManualId?: number;
  monto: number;
  tasa: number;
  montoDepositar: number;
  reciboManualCodigo: number;
  reciboManualFecha: string;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  monedaId: number;
  monedaNombre: string;
  eliminar: boolean;
}

export interface IBancoReportesResumenPagos {
  tipo: string;
  id: number;
  codigo: number;
  companiaId: number;
  bancoCuentaId: number;
  bancoCuentaAplicarId?: number;
  suplidorId?: number;
  cuentaId?: number;
  conciliacionId?: number;
  destinatario?: string;
  monedaId?: number;
  solicitudId?: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha?: string;
  observacion: string;
  monto: number;
  montoRetItbis: number;
  montoRetIsr: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  isPagoOtraMoneda: boolean;
  isPagado: boolean;
  isAnulado: boolean;
  monedaAplicarId: number;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  suplidorRncCedula?: string;
  suplidorNombre?: string;
  suplidorDireccion?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuenta?: string;
  cuentaNombre?: string;
  bancoCuentaAplicarCuentaBancaria?: string;
  bancoCuentaAplicarNombre?: string;
  solicitudCodigo?: number;
  conciliacionCodigo?: number;
  documentos: IBancoReportePagosDocumentos[];
}

export interface IBancoReportePagosDocumentos {
  tipo: string;
  id: number;
  tipoCabeza: string;
  cabezaId: number;
  documentoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente?: number;
  pendienteDespues?: number;
  documentoCodigo: number;
  documentoFecha: string;
  documentoNcf?: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
}

export interface IBancoSolicitud {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId: number;
  suplidorId?: number;
  cuentaId?: number;
  prestamoId?: number;
  monedaId: number;
  monedaAplicarId: number;
  chequeId?: number;
  transferenciaId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  tasa: number;
  destinatario?: string;
  fecha: string;
  observacion: string;
  monto: number;
  montoRetItbis: number;
  montoRetIsr: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  autorizadoPor?: string;
  rechazadoPor?: string;
  isAutorizado: boolean;
  isRechazado: boolean;
  isAnulado: boolean;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  suplidorRncCedula?: string;
  suplidorNombre?: string;
  suplidorDireccion?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuenta?: string;
  cuentaNombre?: string;
  chequeCodigo?: number;
  transferenciaCodigo?: number;
  prestamoCodigo?: number;
  prestamoMontoGastosCierre?: number;
  prestamoMontoGastosLegales?: number;
  pagosFT: IBancoSolicitudPagoFT[];
  pagosND: IBancoSolicitudPagoND[];
  pagosRG: IBancoSolicitudPagoRG[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoSolicitudPagoFT {
  id?: number;
  solicitudId?: number;
  facturaId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  facturaCodigo: string;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  facturaNetoBien: number;
  facturaNetoServicio: number;
  facturaItbis: number;
  eliminar?: boolean;
}

export interface IBancoSolicitudPagoND {
  id?: number;
  solicitudId?: number;
  notaDebitoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBancoSolicitudPagoRG {
  id?: number;
  solicitudId?: number;
  reembolsoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  reembolsoCodigo: number;
  reembolsoFecha: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBancoTransferencia {
  id?: number;
  codigo?: number;
  companiaId: number;
  bancoCuentaId: number;
  bancoCuentaAplicarId?: number;
  suplidorId?: number;
  cuentaId?: number;
  monedaId: number;
  conciliacionId?: number;
  solicitudId?: number;
  prestamoId?: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  numeroReferencia?: string;
  destinatario?: string;
  observacion: string;
  monto: number;
  montoRetItbis: number;
  montoRetIsr: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  isPagoOtraMoneda: boolean;
  isPagado: boolean;
  isAnulado: boolean;
  isSaldarRetencionesEnPago: boolean;
  monedaAplicarId: number;
  bancoCuentaCuentaBancaria: string;
  bancoCuentaNombre: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  suplidorRncCedula?: string;
  suplidorNombre?: string;
  suplidorDireccion?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  cuenta?: string;
  cuentaNombre?: string;
  bancoCuentaAplicarCuentaBancaria?: string;
  bancoCuentaAplicarNombre?: string;
  solicitudCodigo?: number;
  conciliacionCodigo?: number;
  conciliacionFecha?: string;
  prestamoCodigo?: number;
  prestamoMontoGastosCierre?: number;
  prestamoMontoGastosLegales?: number;
  cuentas: ICuenta[];
  pagosFT: IBancoTransferenciaPagoFT[];
  pagosND: IBancoTransferenciaPagoND[];
  pagosRG: IBancoTransferenciaPagoRG[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IBancoTransferenciaPagoFT {
  id?: number;
  transferenciaId?: number;
  facturaId: number;
  retencionItbisId?: number;
  montoRetItbis: number;
  retencionIsrId?: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  facturaCodigo: string;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  facturaNetoBien: number;
  facturaNetoServicio: number;
  facturaItbis: number;
  retencionItbisNombre?: string;
  retencionItbisPorcentaje?: number;
  retencionIsrNombre?: string;
  retencionIsrPorcentaje?: number;
  eliminar?: boolean;
}

export interface IBancoTransferenciaPagoND {
  id?: number;
  transferenciaId?: number;
  notaDebitoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBancoTransferenciaPagoRG {
  id?: number;
  transferenciaId?: number;
  reembolsoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente: number;
  pendienteDespues: number;
  reembolsoCodigo: number;
  reembolsoFecha: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface IBusqueda {
  collection: string;
  index?: number;
  detalle?: string;
  propiedad?: string;
  where?: string;
  order?: string;
  otrosParametros?: object;
  listadoManual?: any[];
  columnas?: Column<any>[];
}

export interface ICompania {
  id: number;
  nombre: string;
  cantidadUsuarios: number;
  utilizaCentroCosto: boolean;
  selectivoPor: number;
  propinaPor: number;
  rnc?: string;
  telefono?: string;
  direccion?: string;
  eMail?: string;
  r: number;
  g: number;
  b: number;
  a: number;
  isInactivo: boolean;
  logo?: string;
  icono?: string;
  usaEcf: boolean;
  ActivoFijoCategorias: IActivoFijoCategoria[];
  ActivoFijoDepartamentos: IActivoFijoDepartamento[];
  ActivoFijoGrupos: IActivoFijoGrupo[];
  monedas: ICompaniaMoneda[];
  almacenes: IInventarioAlmacen[];
  almacenesGrupos: IInventarioAlmacenGrupo[];
  inventarioConfiguracion: IInventarioConfiguracion;
  inventarioLiquidacionAduanalTipoGasto: IInventarioLiquidacionAduanalGasto[];
  inventarioProyectos: IInventarioProyecto[];
  inventarioProductoTipoInventario: IInventarioProductoTipoInventario[];
  inventarioProductoGrupo: IInventarioProductoGrupo[];
  inventarioProductoSubGrupo: IInventarioProductoSubGrupo[];
  centroCostos: IContabilidadCentroCosto[];
  centroCostoProyectos: IContabilidadCentroCostoProyecto[];
  centroCostoSubProyectos: IContabilidadCentroCostoSubProyecto[];
  bancos: IBancoCuenta[];
  CxCConfiguracion: ICxCConfiguracion;
  CxCClienteGrupos: ICxCClienteGrupo[];
  CxCRestaurantMesas: ICxCRestaurantMesa[];
  CxPBuscadores: ICxPBuscador[];
  CxPSuplidorGrupos: ICxPSuplidorGrupo[];
  CxPConfiguracion: ICxPConfiguracion;
  CxPPesadores: ICxPPesador[];
  CxCVendedores: ICxCVendedor[];
  CxCClientes: ICxCCliente[];
  contabilidadConfiguracion: IContabilidadConfiguracion;
  comprobantes: ICompaniaComprobante[];
  tipoComprobantes: IAGlobalTipoComprobante[];
  modulos: ICompaniaModulo[];
  financiamientoConfiguracion: IFinanciamientoConfiguracion;
  visitaConfiguracion: IVisitaConfiguracion;
  tallerMecanicaConfiguracion: ITallerMecanicaConfiguracion;
  bancoChequeConfiguracionImpresion: IBancoChequeConfiguracionImpresion[];
  nominaConfiguracion: INominaConfiguracion;
  RRHHPosiciones: IRRHHPosicion[];
  nominaDepartamentos: INominaDepartamento[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICompaniaComprobante {
  id?: number;
  companiaId?: number;
  fechaInicio: string;
  fechaFin: string;
  serie: string;
  secuenciaDesde: number;
  secuenciaHasta: number;
  reposicion: number;
  tipoNcfId: number;
  digitos: number;
  secuenciaActual: number;
  isInactivo: boolean;
  tipoNcfNombre: string;
  grupo: number;
  eliminar?: boolean;
}

export interface ICompaniaModulo {
  id: number;
  companiaId: number;
  moduloId: number;
  companiaNombre: string;
  moduloNombre: string;
  eliminar?: boolean;
}

export interface ICompaniaMoneda {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  cuentaCxCId?: number;
  cuentaCxCPrimaId?: number;
  cuentaCajaId?: number;
  cuentaCajaPrimaId?: number;
  cuentaCxPId?: number;
  cuentaCxPPrimaId?: number;
  companiaNombre?: string;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
  cuentaCxCPrima?: string;
  cuentaCxCPrimaNombre?: string;
  cuentaCaja?: string;
  cuentaCajaNombre?: string;
  cuentaCajaPrima?: string;
  cuentaCajaPrimaNombre?: string;
  cuentaCxP?: string;
  cuentaCxPNombre?: string;
  cuentaCxPPrima?: string;
  cuentaCxPPrimaNombre?: string;
  eliminar?: boolean;
}

export interface IContabilidadCatalogo {
  id?: number;
  companiaId: number;
  nivel: number;
  nombre: string;
  cuenta: string;
  cuentaControlId?: number;
  cuentaControlNombre?: string;
  cuentaControl?: string;
  tipoCuentaId: number;
  nombreTipoCuenta: string;
  origen: string;
  origenTexto: string;
  isControl: boolean;
  controlTexto: string;
  isInactivo: boolean;
  verEnEstResSit: boolean;
  inactivoTexto: string;
}

export interface IContabilidadCentroCosto {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
}

export interface IContabilidadCentroCostoEnlace {
  id?: number;
  idEnlace?: string;
  nombre: string;
  padreId: number;
  parentId?: string;
  parentName: string;
  companiaId: number;
  isInactivo: boolean;
  nivel: number;
  tipo: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IContabilidadCentroCostoProyecto {
  id?: number;
  centroCostoId: number;
  nombre: string;
  isInactivo: boolean;
  centroCostoNombre: string;
  companiaId: number;
}

export interface IContabilidadCentroCostoSubProyecto {
  id?: number;
  centroCostoProyectoId: number;
  nombre: string;
  isInactivo: boolean;
  centroCostoNombre: string;
  centroCostoId: number;
  centroCostoProyectoNombre: string;
  companiaId: number;
}

export interface IContabilidadConfiguracion {
  id: number;
  companiaId: number;
  cuentaCierreEjercicioContableId?: number;
  cuentaCxCItbisId?: number;
  cuentaCxPItbisId?: number;
  cuentaCxPItbisCostoId?: number;
  cuentaDescuentoId?: number;
  cuentaPropinaId?: number;
  cuentaSelectivoId?: number;
  cuentaOtrosImpuestosId?: number;
  cuentaIngresoNotaDebitoClienteId?: number;
  cuentaCxCRetItbisId?: number;
  cuentaCxCRetIsrId?: number;
  cuentaCxPRetItbisId?: number;
  cuentaCxPRetIsrId?: number;
  cuentaCreditoNDSuplidorId?: number;
  cuentaDebitoNCClienteId?: number;
  cuentaCreditoNCSuplidorId?: number;
  cuentaDebitoDVClienteId?: number;
  cuentaCierreEjercicioContable?: string;
  cuentaCierreEjercicioContableNombre?: string;
  cuentaCxCItbis?: string;
  cuentaCxCItbisNombre?: string;
  cuentaCxPItbis?: string;
  cuentaCxPItbisNombre?: string;
  cuentaCxPItbisCosto?: string;
  cuentaCxPItbisCostoNombre?: string;
  cuentaDescuento?: string;
  cuentaDescuentoNombre?: string;
  cuentaPropina?: string;
  cuentaPropinaNombre?: string;
  cuentaSelectivo?: string;
  cuentaSelectivoNombre?: string;
  cuentaOtrosImpuestos?: string;
  cuentaOtrosImpuestosNombre?: string;
  cuentaIngresoNotaDebitoCliente?: string;
  cuentaIngresoNotaDebitoClienteNombre?: string;
  cuentaCxCRetItbis?: string;
  cuentaCxCRetItbisNombre?: string;
  cuentaCxCRetIsr?: string;
  cuentaCxCRetIsrNombre?: string;
  cuentaCxPRetItbis?: string;
  cuentaCxPRetItbisNombre?: string;
  cuentaCxPRetIsr?: string;
  cuentaCxPRetIsrNombre?: string;
  cuentaCreditoNDSuplidor?: string;
  cuentaCreditoNDSuplidorNombre?: string;
  cuentaDebitoNCCliente?: string;
  cuentaDebitoNCClienteNombre?: string;
  cuentaCreditoNCSuplidor?: string;
  cuentaCreditoNCSuplidorNombre?: string;
  cuentaDebitoDVCliente?: string;
  cuentaDebitoDVClienteNombre?: string;
  cuentaCxCPrestamoId?: number;
  cuentaGastosCierreId?: number;
  cuentaGastosLegalesId?: number;
  cuentaCxCPrestamo?: string;
  cuentaCxCPrestamoNombre?: string;
  cuentaGastosCierre?: string;
  cuentaGastosCierreNombre?: string;
  cuentaGastosLegales?: string;
  cuentaGastosLegalesNombre?: string;
  bancoSaldarRetencionesEnPago: boolean;
  cuentaInventarioLiquidacionTransitoId?: number;
  cuentaInventarioLiquidacionTransito?: string;
  cuentaInventarioLiquidacionTransitoNombre?: string;
  cuentaPesadaId?: number;
  cuentaPesada?: string;
  cuentaPesadaNombre?: string;
  cuentaInventarioProduccionTransitoId?: number;
  cuentaInventarioProduccionTransito?: string;
  cuentaInventarioProduccionTransitoNombre?: string;
  isRegistroAbierto: boolean;
}
export interface IContabilidadEntradaDiarioManual {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  concepto: string;
  destinatario?: string;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  isAnulado: boolean;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IContabilidadPeriodoContableAbierto {
  id?: number;
  companiaId: number;
  ano: number;
  mes: number;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IContabilidadEjercicioContableCerrado {
  id?: number;
  companiaId: number;
  ano: number;
  mes: number;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IContabilidadEntradaDiario {
  tipo: string;
  id: number;
  codigo?: string;
  companiaId: number;
  fecha?: string;
  concepto?: string;
  destinatario?: string;
  detalleId: number;
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  tipoCuentaId: number;
  nombreTipoCuenta: string;
  debito: number;
  credito: number;
  centroCostoId?: number;
  centroCostoNombre?: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  origen: number;
  origenTexto: string;
  cuentaControlId: number;
  cuentaControl: string;
  cuentaControlNombre: string;
}

export interface IContabilidadPresupuesto {
  id?: number;
  codigo?: number;
  companiaId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  mesInicio: number;
  anoInicio: number;
  mesFin: number;
  anoFin: number;
  observacion: string;
  total: number;
  isAnulado: boolean;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuentas: IContabilidadPresupuestoCuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IContabilidadPresupuestoCuenta {
  id?: number;
  presupuestoId?: number;
  cuentaId: number;
  mes: number;
  ano: number;
  monto: number;
  cuenta: string;
  cuentaNombre: string;
  eliminar?: boolean;
}

export interface ISPContabilidadPresupuestoCuentaEjecucion {
  id: number;
  presupuestoId: number;
  presupuestoCodigo: number;
  cuentaId: number;
  mes: number;
  ano: number;
  monto: number;
  cuenta: string;
  cuentaNombre: string;
  debito: number;
  credito: number;
  ejecutado: number;
  montoPorEjecutar: number;
  porEjecutado: number;
}

export interface IContabilidadMayorGeneral {
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  balanceAnterior: number;
  debito: number;
  credito: number;
  balanceActual: number;
  control: boolean;
  cuentaControlId: number;
  movimientos: IContabilidadRepEntradaDiario[];
}

export interface IContabilidadRepEntradaDiario {
  tipo: string;
  id: number;
  codigo: string;
  companiaId: number;
  fecha: string;
  concepto: string;
  destinatario?: string;
  detalleId: number;
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  tipoCuentaId: number;
  nombreTipoCuenta: string;
  debito: number;
  credito: number;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId: number;
  centroCostoProyectoNombre: string;
  centroCostoSubProyectoId: number;
  centroCostoSubProyectoNombre: string;
  origen: number;
  origenTexto: string;
  cuentaControlId: number;
  cuentaControl: string;
  cuentaControlNombre: string;
}

export interface IContabilidadRepSPBalanceCuentaTodos {
  nivel: number;
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  cuentaControlId: number;
  cuentaControl: string;
  cuentaControlNombre: string;
  origen: number;
  origenTexto: string;
  tipoCuenta: number;
  tipoCuentaNombre: string;
  balance: number;
  control: boolean;
  controlTexto: string;
}

export interface IContabilidadRepSituacion {
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  balanceMes1: number;
  balanceMes2: number;
  variacion: number;
  variacionPor: number;
  control: boolean;
}

export interface IContabilidadRepEstadoResultado2Anos {
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  cuentaControlId: number;
  cuentaControl: string;
  cuentaControlNombre: string;
  origen: number;
  origenTexto: string;
  tipoCuenta: number;
  tipoCuentaNombre: string;
  balance1Mes1: number;
  balance2Mes1: number;
  variacionMes1: number;
  variacionPorcMes1: number;
  balance1Mes2: number;
  balance2Mes2: number;
  variacionMes2: number;
  variacionPorcMes2: number;
  balance1Mes3: number;
  balance2Mes3: number;
  variacionMes3: number;
  variacionPorcMes3: number;
  balance1Mes4: number;
  balance2Mes4: number;
  variacionMes4: number;
  variacionPorcMes4: number;
  balance1Mes5: number;
  balance2Mes5: number;
  variacionMes5: number;
  variacionPorcMes5: number;
  balance1Mes6: number;
  balance2Mes6: number;
  variacionMes6: number;
  variacionPorcMes6: number;
  balance1Mes7: number;
  balance2Mes7: number;
  variacionMes7: number;
  variacionPorcMes7: number;
  balance1Mes8: number;
  balance2Mes8: number;
  variacionMes8: number;
  variacionPorcMes8: number;
  balance1Mes9: number;
  balance2Mes9: number;
  variacionMes9: number;
  variacionPorcMes9: number;
  balance1Mes10: number;
  balance2Mes10: number;
  variacionMes10: number;
  variacionPorcMes10: number;
  balance1Mes11: number;
  balance2Mes11: number;
  variacionMes11: number;
  variacionPorcMes11: number;
  balance1Mes12: number;
  balance2Mes12: number;
  variacionMes12: number;
  variacionPorcMes12: number;
}

export interface IContabilidadRepEstadoSituacion2Anos {
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  cuentaControlId: number;
  cuentaControl: string;
  cuentaControlNombre: string;
  origen: number;
  origenTexto: string;
  tipoCuenta: number;
  tipoCuentaNombre: string;
  balance1Mes1: number;
  balance2Mes1: number;
  variacionMes1: number;
  variacionPorcMes1: number;
  balance1Mes2: number;
  balance2Mes2: number;
  variacionMes2: number;
  variacionPorcMes2: number;
  balance1Mes3: number;
  balance2Mes3: number;
  variacionMes3: number;
  variacionPorcMes3: number;
  balance1Mes4: number;
  balance2Mes4: number;
  variacionMes4: number;
  variacionPorcMes4: number;
  balance1Mes5: number;
  balance2Mes5: number;
  variacionMes5: number;
  variacionPorcMes5: number;
  balance1Mes6: number;
  balance2Mes6: number;
  variacionMes6: number;
  variacionPorcMes6: number;
  balance1Mes7: number;
  balance2Mes7: number;
  variacionMes7: number;
  variacionPorcMes7: number;
  balance1Mes8: number;
  balance2Mes8: number;
  variacionMes8: number;
  variacionPorcMes8: number;
  balance1Mes9: number;
  balance2Mes9: number;
  variacionMes9: number;
  variacionPorcMes9: number;
  balance1Mes10: number;
  balance2Mes10: number;
  variacionMes10: number;
  variacionPorcMes10: number;
  balance1Mes11: number;
  balance2Mes11: number;
  variacionMes11: number;
  variacionPorcMes11: number;
  balance1Mes12: number;
  balance2Mes12: number;
  variacionMes12: number;
  variacionPorcMes12: number;
}

export interface IContabilidadRepMovimientoCuentaGrafico {
  mesNombre: string;
  mes: number;
  valor: number;
}

export interface IDashBoardGraficoUsuarioCompania {
  id?: number;
  companiaId: number;
  usuarioId: number;
  opcionGraficoId: number;
  data1?: string;
  data2?: string;
  data3?: string;
  data4?: string;
  data5?: string;
  data6?: string;
  data7?: string;
  data8?: string;
  data9?: string;
  data10?: string;
  accesoId: number;
  nombre: string;
  rutaAPI: string;
}

export interface IFinanciamientoConfiguracion {
  id?: number;
  companiaId: number;
  frecuenciaId: number;
  frecuencia: number;
  frecuenciaNombre: string;
  porMora: number;
  diasLimitePago: number;
  sumarGastos: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IFinanciamientoPrestamo {
  id?: number;
  codigo?: number;
  companiaId: number;
  monedaId: number;
  clienteId: number;
  clienteNombre?: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  frecuenciaId: number;
  solicitudId?: number;
  chequeId?: number;
  transferenciaId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  tipoCalculo: number;
  fecha: string;
  fechaInicio: string;
  observacion?: string;
  montoPrestamo: number;
  montoGastosCierre: number;
  montoGastosLegales: number;
  montoDesembolsar: number;
  montoInteres: number;
  total: number;
  montoPago: number;
  tasaInteresAnual: number;
  tasaInteresCuota: number;
  cantidadCuotas: number;
  isAutorizado: boolean;
  isRechazado: boolean;
  autorizadoPor?: string;
  rechazadoPor?: string;
  isPagado: boolean;
  frecuenciaNombre: string;
  frecuencia: number;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  chequeCodigo?: number;
  transferenciaCodigo?: number;
  solicitudCodigo?: number;
  cuotas: IFinanciamientoPrestamoCuota[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IFinanciamientoPrestamoCuota {
  id?: number;
  prestamoId?: number;
  cuotaNo: number;
  fecha: string;
  montoInicial: number;
  montoPago: number;
  montoCapital: number;
  montoInteres: number;
  montoMora: number;
  montoFinal: number;
  montoCapitalPagado: number;
  montoInteresPagado: number;
  montoMoraPagado: number;
  isPagado: boolean;
  montoCapitalPendiente: number;
  montoInteresPendiente: number;
  montoMoraPendiente: number;
  eliminar?: boolean;
}

export interface IFinanciamientoReporteCarteraPrestamo {
  id: number;
  prestamoId: number;
  prestamoCodigo: number;
  cuotaNo: number;
  fecha: string;
  clienteId: number;
  clienteRncCedula: string;
  clienteNombre: string;
  montoInicial: number;
  montoPago: number;
  montoCapital: number;
  montoInteres: number;
  montoMora: number;
  montoFinal: number;
  montoCapitalPagado: number;
  montoInteresPagado: number;
  montoMoraPagado: number;
  isPagado: boolean;
  montoCapitalPendiente: number;
  montoInteresPendiente: number;
  montoMoraPendiente: number;
}

export interface IFinanciamientoRecibo {
  id?: number;
  codigo?: number;
  companiaId: number;
  tipo: number;
  clienteId: number;
  clienteNombre?: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  depositoId?: number;
  medioPagoId: number;
  prestamoId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  observacion: string;
  total: number;
  isPagado: boolean;
  isAnulado: boolean;
  banco: string;
  documento: string;
  tipoReferencia: string;
  medioPagoNombre: string;
  depositoCodigo?: number;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  prestamoCodigo?: number;
  tasaInteresCuota?: number;
  tipoCalculo?: number;
  cuentas: ICuenta[];
  pagosCuota: IFinanciamientoReciboPagoCuota[];
  prestamoCuotas: IFinanciamientoReciboPrestamoCuota[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IFinanciamientoReciboPagoCuota {
  id?: number;
  reciboId?: number;
  cuotaId: number;
  montoPagado: number;
  montoPagadoCapital: number;
  montoPagadoInteres: number;
  montoPagadoMora: number;
  montoCapitalPendiente: number;
  montoInteresPendiente: number;
  montoMoraPendiente: number;
  montoCapitalPendienteDespues: number;
  montoInteresPendienteDespues: number;
  montoMoraPendienteDespues: number;
  montoPago: number;
  montoCapital: number;
  montoInteres: number;
  montoMora: number;
  cuotaNo: number;
  prestamoCodigo: number;
  clienteRncCedula: string;
  clienteNombre: string;
  eliminar?: boolean;
}

export interface IFinanciamientoReciboPrestamoCuota {
  id?: number;
  reciboId?: number;
  cuotaId: number;
  cuotaNo: number;
  fecha: string;
  montoInicialOriginal: number;
  montoPagoOriginal: number;
  montoCapitalOriginal: number;
  montoInteresOriginal: number;
  montoMoraOriginal: number;
  montoFinalOriginal: number;
  montoInicial: number;
  montoPago: number;
  montoCapital: number;
  montoInteres: number;
  montoMora: number;
  montoFinal: number;
  eliminar?: boolean;
}

export interface IEntradaDiario {
  id?: number;
  tipo: string;
  codigo?: string;
  companiaId: number;
  fecha: string;
  concepto: string;
  destinatario?: string;
  cuentas: (ICuenta & { origen: number; origenTexto: string })[];
}

export interface ICuenta {
  id?: number;
  cuentaId: number;
  cuenta: string;
  cuentaNombre: string;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  debito: number;
  credito: number;
  eliminar?: boolean;
}

export interface ICxCCliente {
  id?: number;
  companiaId: number;
  municipio?: string;
  provincia?: string;
  isInactivo: boolean;
  codigoInterno?: string;
  rncCedula: string;
  nombre: string;
  nombreComercial?: string;
  limiteCredito: number;
  condicionPago: number;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  eMail?: string;
  direccion?: string;
  foto?: string;
  fechaCumpleanos?: string;
  contacto?: string;
  grupoId: number;
  grupoCuentaCxCId?: number;
  grupoCuentaCxC?: string;
  grupoCuentaCxCNombre?: string;
  vendedorId: number;
  tipoComprobanteId?: number;
  clasificacionId?: number;
  grupoPrecio: number;
  cumpleanosDia: number;
  cumpleanosMes: number;
  grupoNombre: string;
  vendedorNombre: string;
  isExtranjero: boolean;
  tipoComprobanteNombre?: string;
  balances: ICxCClienteBalance[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCClienteBalance {
  id?: number;
  clienteId: number;
  monedaId: number;
  balance: number;
  monedaNombre: string;
}

export interface ICxCClienteGrupo {
  id?: number;
  companiaId: number;
  cuentaCxCId?: number;
  nombre: string;
  isInactivo: boolean;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
}

export interface ICxCClienteLocalidad {
  id?: number;
  companiaId: number;
  clienteId: number;
  nombre: string;
  observacion?: string;
  isInactivo: boolean;
  clienteNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCConfiguracion {
  id?: number;
  companiaId: number;
  cotizacionDiasValidez: number;
  facturaImprimirGrabar: boolean;
  tipoImpresionFactura: number;
  tipoImpresionRecibo: number;
  tipoImpresionND: number;
  tipoImpresionNC: number;
  tamanoLetraNC: number;
  tamanoLetraND: number;
  modoImpresionDespacho: number;
  notaPieFactura?: string;
  notaPieCotizacion?: string;
  requerirAutorizacionDebajoMinVenta: boolean;
  porcentajeMinimoVenta: number;
  porcentajeSugeridoVenta: number;
  notifEstadoLunes: boolean;
  notifEstadoMartes: boolean;
  notifEstadoMiercoles: boolean;
  notifEstadoJueves: boolean;
  notifEstadoViernes: boolean;
  notifEstadoSabado: boolean;
  notifEstadoDomingo: boolean;
  notifEstadoDiasAVencer: number;
  notifEstadoHora: number;
  notifEstadoCabeza?: string;
  notifEstadoPie?: string;
  logoCompaniaWidth: number;
  logoCompaniaHeight: number;
  sello?: string;
  firma?: string;
  isImprimirInfoCompania: boolean;
  isImprimirInfoCompaniaRecibo: boolean;
  isImprimirInfoUsuarioFecha: boolean;
  margenSuperior: number;
  margenSuperiorRecibo: number;
  margenSuperiorNC: number;
  margenSuperiorND: number;
  tamanoLetraFactura: number;
  tamanoLetraRecibo: number;
  isImpresionBlancoNegro: boolean;
  mostrarPrecioItbisFacturacion: boolean;
  comisionVendedor: ICxCConfiguracionComisionVendedor[];
  comisionVendedorGov: ICxCConfiguracionComisionVendedorGov[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCConfiguracionComisionVendedor {
  id?: number;
  configuracionId?: number;
  diasDesde: number;
  diasHasta: number;
  porcentajeComision: number;
  eliminar?: boolean;
}

export interface ICxCConfiguracionComisionVendedorGov {
  id?: number;
  configuracionId?: number;
  diasDesde: number;
  diasHasta: number;
  porcentajeComision: number;
  eliminar?: boolean;
}

export interface ICxCCotizacion {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  fechaVence: string;
  diasCredito: number;
  monedaId: number;
  estadoId?: number;
  vendedorId: number;
  isCredito: boolean;
  observacion?: string;
  isAnulado: boolean;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  clienteId: number;
  clienteNombre?: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  bruto: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  utilidad: number;
  utilidadPor: number;
  facturaId?: number;
  monedaNombre: string;
  cuentaCxCId?: number;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
  cuentaCxCPrimaId?: number;
  cuentaCxCPrima?: string;
  cuentaCxCPrimaNombre?: string;
  vendedorNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  estadoNombre?: string;
  facturaCodigo?: number;
  productos: ICxCCotizacionProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCCotizacionEstado {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCCotizacionProducto {
  id?: number;
  cotizacionId?: number;
  productoId: number;
  unidadMedidaId: number;
  unidadMedidaNombre?: string;
  productoNombre: string;
  cantidad: number;
  cantidadEquivalencia: number;
  equivalencia: number;
  precio: number;
  bruto: number;
  porcentajeMinimoVenta: number;
  porcentajeSugeridoVenta: number;
  descuentoPor: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  itbisPor: number;
  utilidad: number;
  utilidadPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  costo: number;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxCCuadreCaja {
  id?: number;
  codigo?: number;
  companiaId: number;
  usuarioId: number;
  usuarioSupervisorId?: number;
  fechaInicio: string;
  fechaCierre?: string;
  isCerrado: boolean;
  montoInicio: number;
  NOZ?: number;
  notas?: string;
  efectivo1: number;
  efectivo5: number;
  efectivo10: number;
  efectivo25: number;
  efectivo50: number;
  efectivo100: number;
  efectivo200: number;
  efectivo500: number;
  efectivo1000: number;
  efectivo2000: number;
  efectivoTotal: number;
  efectivoEnCaja: number;
  usuarioNombre: string;
  usuarioSupervisorNombre?: string;
  distribucionIngresos: ICxCCuadreCajaDistPagos[];
  creditosClientes: ICxCCuadreCajaCreditosClientes[];
  descuentosDevoluciones: ICxCCuadreCajaDescuentosDevoluciones[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCCuadreCajaDistPagos {
  cuadreCajaId: number;
  monedaId: number;
  monedaNombre: string;
  medioPagoId: number;
  medioPagoNombre: string;
  montoCredito: number;
  montoContado: number;
  montoSalida: number;
  montoTotal: number;
}

export interface ICxCCuadreCajaCreditosClientes {
  cuadreCajaId: number;
  monedaNombre: string;
  clienteNombre: string;
  total: number;
}

export interface ICxCCuadreCajaDescuentosDevoluciones {
  cuadreCajaId: number;
  monedaNombre: string;
  clienteNombre: string;
  total: number;
}

export interface ICxCDespacho {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion?: string;
  isAnulado: boolean;
  facturas: ICxCDespachoFactura[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCDespachoFactura {
  id?: number;
  despachoId?: number;
  facturaId: number;
  companiaId: number;
  facturaCodigo: number;
  clienteDestinatario: string;
  facturaFecha: string;
  clienteProvincia?: string;
  clienteMunicipio?: string;
  productos: ICxCFacturaProducto[];
  eliminar?: boolean;
}

export interface ICxCFactura {
  id?: number;
  codigo?: number;
  codigoInterno?: string;
  companiaId: number;
  fecha: string;
  fechaVence: string;
  tipoNcfId: number;
  ncf: string;
  tipoIngresoId: number;
  nombreComercial?: string;
  monedaId: number;
  vendedorId: number;
  cotizacionId?: number;
  prefacturaId?: number;
  proformaId?: number;
  tasa: number;
  isCredito: boolean;
  atencion?: string;
  observacion?: string;
  isAnulado: boolean;
  tipoAnulacionId?: number;
  almacenId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  clienteId: number;
  clienteNombre?: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  terminoPago?: string;
  bruto: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  devolucionEfectivo: number;
  ncfVence?: string;
  isPagado: boolean;
  cuadreCajaId?: number;
  utilidad: number;
  utilidadPor: number;
  mesaId: number;
  usuarioId: number;
  resumenFacturaResumenId?: number;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  cuadreCajaCodigo?: number;
  tipoIngresoNombre: string;
  monedaNombre: string;
  cuentaCxCId?: number;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
  cuentaCxCPrimaId?: number;
  cuentaCxCPrima?: string;
  cuentaCxCPrimaNombre?: string;
  cuentaCajaId?: number;
  cuentaCaja?: string;
  cuentaCajaNombre?: string;
  cuentaCajaPrimaId?: number;
  cuentaCajaPrima?: string;
  cuentaCajaPrimaNombre?: string;
  cuentaItbisId?: number;
  cuentaItbis?: string;
  cuentaItbisNombre?: string;
  cuentaDescuentoId?: number;
  cuentaDescuento?: string;
  cuentaDescuentoNombre?: string;
  cuentaPropinaId?: number;
  cuentaPropina?: string;
  cuentaPropinaNombre?: string;
  cuentaSelectivoId?: number;
  cuentaSelectivo?: string;
  cuentaSelectivoNombre?: string;
  cuentaOtrosImpuestosId?: number;
  cuentaOtrosImpuestos?: string;
  cuentaOtrosImpuestosNombre?: string;
  vendedorNombre: string;
  codigoInternoVendedor?: string;
  almacenGrupoNombre: string;
  almacenNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  tipoNcfNombre: string;
  cotizacionCodigo?: number;
  tipoAnulacionNombre?: string;
  mesaNombre: string;
  usuarioNombre: string;
  prefacturaCodigo?: number;
  proformaCodigo?: number;
  clienteCondicionPago?: number;
  grupoCuentaCxCId?: number;
  grupoCuentaCxC?: string;
  grupoCuentaCxCNombre?: string;
  cuentas: ICuenta[];
  productos: ICxCFacturaProducto[];
  pagos: ICxCFacturaPago[];
  pagosRC?: ICxCReciboPagoFT[];
  pagosNC?: ICxCNotaCreditoPagoFT[];
  evento?: IAGlobalEvento;
  tableData?: any;
  eliminar?: boolean;
}

export interface ICxCFacturaPago {
  id?: number;
  facturaId?: number;
  medioPagoId: number;
  monto: number;
  tipoCuentaPago?: string;
  numeroCuentaPago?: string;
  bancoPago?: string;
  medioPagoNombre: string;
  monedaNombre: string;
  clienteNombre?: string;
  observacion?: string;
  cuadreCajaId?: number;
  facturaCodigo?: number;
  eliminar?: boolean;
}

export interface ICxCFacturaProducto {
  id?: number;
  facturaId?: number;
  productoId: number;
  almacenId: number;
  unidadMedidaId: number;
  unidadMedidaNombre?: string;
  productoNombre: string;
  cantidad: number;
  cantidadEquivalencia: number;
  equivalencia: number;
  costo: number;
  precio: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  itbisPor: number;
  utilidad: number;
  utilidadPor: number;
  almacenNombre: string;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  tipoITBISId?: number;
  pesoUnitario?: number;
  eliminar?: boolean;
}

export interface ICxCNotaDebito {
  id?: number;
  codigo?: number;
  companiaId: number;
  almacenId: number;
  monedaId: number;
  clienteId: number;
  clienteNombre: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  facturaAfectadaId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  ncfVence?: string;
  ncf?: string;
  ncfAfectado?: string;
  observacion: string;
  tasa: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  isAjuste: boolean;
  isAnulado: boolean;
  isPagado: boolean;
  grupoCuentaCxCId?: number;
  grupoCuentaCxC?: string;
  grupoCuentaCxCNombre?: string;
  cuadreCajaId?: number;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  facturaFecha?: string;
  facturaFechaVence?: string;
  monedaNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  facturaTipoIngresoId?: number;
  facturaTipoIngresoNombre?: string;
  cuadreCajaCodigo?: number;
  cuentas: ICuenta[];
  productos: ICxCNotaDebitoProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
  codigoModificacion: number;
}

export interface ICxCNotaDebitoProducto {
  id?: number;
  notaDebitoId?: number;
  productoId: number;
  almacenId: number;
  productoNombre: string;
  cantidad: number;
  costo: number;
  precio: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  itbisPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxCNotaCredito {
  id?: number;
  codigo?: number;
  companiaId: number;
  clienteId: number;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  monedaId: number;
  monedaAplicarId: number;
  facturaAfectadaId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  almacenId: number;
  fecha: string;
  ncf?: string;
  ncfVence?: string;
  ncfAfectado?: string;
  observacion: string;
  tasa: number;
  monto: number;
  montoItbis: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  grupoCuentaCxCId?: number;
  grupoCuentaCxC?: string;
  grupoCuentaCxCNombre?: string;
  isAjuste: boolean;
  isAnulado: boolean;
  isDevolucion: boolean;
  cuadreCajaId?: number;
  facturaFechaVence?: string;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  facturaFecha?: string;
  clienteNombre?: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  facturaTipoIngresoId?: number;
  facturaTipoIngresoNombre?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuadreCajaCodigo?: number;
  almacenNombre: string;
  almacenGrupoNombre: string;
  cuentas: ICuenta[];
  pagosFT: ICxCNotaCreditoPagoFT[];
  pagosND: ICxCNotaCreditoPagoND[];
  productos: ICxCNotaCreditoProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
  codigoModificacion: number;
}

export interface ICxCNotaCreditoPagoFT {
  id?: number;
  notaCreditoId?: number;
  facturaId: number;
  pendiente?: number;
  montoPagado: number;
  pendienteDespues?: number;
  facturaCodigo: number;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  observacion?: string;
  companiaId?: number;
  eliminar?: boolean;
}

export interface ICxCNotaCreditoPagoND {
  id?: number;
  notaCreditoId?: number;
  notaDebitoId: number;
  pendiente?: number;
  montoPagado: number;
  pendienteDespues?: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  observacion?: string;
  companiaId?: number;
  eliminar?: boolean;
}
export interface ICxCNotaCreditoProducto {
  id?: number;
  notaCreditoId?: number;
  productoId: number;
  almacenId: number;
  productoNombre: string;
  cantidad: number;
  costo: number;
  precio: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  itbisPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxCPrefactura {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  monedaId: number;
  vendedorId: number;
  isCredito: boolean;
  observacion?: string;
  isAnulado: boolean;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  clienteId: number;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  bruto: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  facturaId?: number;
  utilidad: number;
  utilidadPor: number;
  mesaId: number;
  usuarioId: number;
  monedaNombre: string;
  cuentaCxCId?: number;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
  cuentaCxCPrimaId?: number;
  cuentaCxCPrima?: string;
  cuentaCxCPrimaNombre?: string;
  vendedorNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  clienteNombre?: string;
  facturaCodigo?: number;
  mesaNombre: string;
  usuarioNombre: string;
  productos: ICxCPrefacturaProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCPrefacturaProducto {
  id?: number;
  prefacturaId?: number;
  productoId: number;
  unidadMedidaId: number;
  unidadMedidaNombre?: string;
  productoNombre: string;
  cantidad: number;
  cantidadEquivalencia: number;
  equivalencia: number;
  costo: number;
  precio: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  itbisPor: number;
  utilidad: number;
  utilidadPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxCProforma {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  fechaVence: string;
  monedaId: number;
  vendedorId: number;
  isCredito: boolean;
  observacion?: string;
  isAnulado: boolean;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  clienteId: number;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  bruto: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  facturaId?: number;
  utilidad: number;
  utilidadPor: number;
  monedaNombre: string;
  cuentaCxCId?: number;
  cuentaCxC?: string;
  cuentaCxCNombre?: string;
  cuentaCxCPrimaId?: number;
  cuentaCxCPrima?: string;
  cuentaCxCPrimaNombre?: string;
  vendedorNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  clienteNombre?: string;
  facturaCodigo?: number;
  productos: ICxCProformaProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCProformaProducto {
  id?: number;
  proformaId?: number;
  productoId: number;
  unidadMedidaId: number;
  unidadMedidaNombre?: string;
  productoNombre: string;
  cantidad: number;
  cantidadEquivalencia: number;
  equivalencia: number;
  precio: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  recargo: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  itbisPor: number;
  utilidad: number;
  utilidadPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxCRecibo {
  id?: number;
  codigo?: number;
  companiaId: number;
  clienteId: number;
  clienteNombre?: string;
  clienteDestinatario: string;
  clienteRncCedula?: string;
  clienteDireccion?: string;
  clienteTelefono1?: string;
  monedaId: number;
  monedaAplicarId: number;
  depositoId?: number;
  medioPagoId: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  observacion: string;
  montoRecibido: number;
  montoRetItbis: number;
  montoRetIsr: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  isPagoOtraMoneda: boolean;
  isPagado: boolean;
  isAnulado: boolean;
  banco: string;
  documento: string;
  tipoReferencia: string;
  grupoCuentaCxCId?: number;
  grupoCuentaCxC?: string;
  grupoCuentaCxCNombre?: string;
  cuadreCajaId?: number;
  monedaNombre: string;
  medioPagoNombre: string;
  monedaAplicarNombre: string;
  depositoCodigo?: number;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuadreCajaCodigo?: number;
  cuentas: ICuenta[];
  pagosFT: ICxCReciboPagoFT[];
  pagosND: ICxCReciboPagoND[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCReciboManual {
  id?: number;
  codigo?: number;
  companiaId: number;
  clienteDestinatario: string;
  monedaId: number;
  depositoId?: number;
  medioPagoId: number;
  tasa: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  observacion: string;
  total: number;
  totalTasa: number;
  isAnulado: boolean;
  banco: string;
  documento: string;
  cuadreCajaId?: number;
  monedaNombre: string;
  medioPagoNombre: string;
  depositoCodigo?: number;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuadreCajaCodigo?: number;
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCReciboPagoFT {
  id?: number;
  reciboId?: number;
  facturaId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente?: number;
  pendienteDespues?: number;
  facturaCodigo: number;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  medioPagoNombre: string;
  observacion?: string;
  companiaId: number;
  eliminar?: boolean;
}

export interface ICxCReciboPagoND {
  id?: number;
  reciboId?: number;
  notaDebitoId: number;
  montoRetItbis: number;
  montoRetIsr: number;
  montoPagado: number;
  pendiente?: number;
  pendienteDespues?: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  medioPagoNombre: string;
  observacion?: string;
  companiaId: number;
  eliminar?: boolean;
}

export interface ICxCReporteComisionVendedor {
  reciboCodigo: number;
  reciboFecha: string;
  clienteId: number;
  clienteNombre: string;
  facturaFecha: string;
  tipoNcfId: number;
  tipoNcfNombre: string;
  facturaCodigo: number;
  vendedorId: number;
  vendedorNombre: string;
  monedaId: number;
  monedaNombre: string;
  diasPagos: number;
  neto: number;
  utilidad: number;
  utilidadPor: number;
  porcentajeComision: number;
  montoComision: number;
}

export interface ICxCRestaurantMesa {
  id?: number;
  companiaId: number;
  nombre: string;
  ubicacion?: string;
  isInactivo: boolean;
  ordenes: ICxCPrefactura[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCResumenFacturaConsumo {
  id?: number;
  codigo?: number;
  companiaId: number;
  ncf: string;
  fecha: string;
  usuarioId: number;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  facturas: ICxCFactura[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxCSalidasCaja {
  id?: number;
  codigo?: number;
  companiaId: number;
  monedaId: number;
  cuadreCajaId?: number;
  tasa: number;
  fecha: string;
  observacion?: string;
  total: number;
  totalTasa: number;
  centroCostoId?: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  isAnulado: boolean;
  monedaNombre: string;
  cuadreCajaCodigo?: number;
  centroCostoNombre?: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  evento?: IAGlobalEvento;
  cuentas: ICuenta[];
  estado: string;
}

export interface ICxCVendedor {
  id?: number;
  companiaId: number;
  nombre: string;
  direccion?: string;
  telefono?: string;
  celular?: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ISPCxPEstadoCuentaBalance {
  id: number;
  rncCedula: string;
  nombre: string;
  monedaId: number;
  monedaNombre: string;
  balance: number;
}

export interface ICxPBuscador {
  id?: number;
  companiaId: number;
  nombre: string;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  comisionPorcentaje: number;
  isInactivo: boolean;
  isSync: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPConfiguracion {
  id?: number;
  companiaId: number;
  almacenPesadaId?: number;
  tipoImpresionOrdenCompra: number;
  isImpresionBlancoNegro: boolean;
  almacenPesadaNombre?: string;
}

export interface ICxPFactura {
  id?: number;
  codigo?: string;
  companiaId: number;
  fecha: string;
  fechaVence: string;
  codigoInterno?: string;
  codigoPedidoInterno?: string;
  ncf: string;
  ncfVence?: string;
  solicitudId?: number;
  monedaId: number;
  ordenId?: number;
  pesadaId?: number;
  almacenId: number;
  tipoBienServicioId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  suplidorId: number;
  tasa: number;
  observacion?: string;
  numeroCuentaPago?: string;
  bancoPago?: string;
  bruto?: number;
  neto?: number;
  descuento?: number;
  netoServicio: number;
  netoBien: number;
  itbisFacturado: number;
  itbisCosto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  retencionItbisId?: number;
  retencionItbis: number;
  retencionIsrId?: number;
  retencionIsr: number;
  cuentaId?: number;
  isServicio: boolean;
  isPagado: boolean;
  isInformal: boolean;
  isGastoMenor: boolean;
  isPagoExterior: boolean;
  isAnulado: boolean;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  monedaNombre: string;
  almacenNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  suplidorRncCedula: string;
  suplidorNombre: string;
  suplidorDireccion?: string;
  ordenCodigo?: number;
  retencionItbisNombre?: string;
  retencionItbisPorcentaje?: number;
  retencionIsrNombre?: string;
  retencionIsrPorcentaje?: number;
  tipoBienServicioNombre: string;
  tipoBienServicioCodigo606: number;
  cuenta?: string;
  cuentaNombre?: string;
  solicitudCodigo?: number;
  pesadaCodigo?: number;
  cuentas: ICuenta[];
  productos: ICxPFacturaProducto[];
  servicios: ICxPFacturaServicio[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPFacturaProducto {
  id?: number;
  facturaId?: number;
  productoId: number;
  productoOrdenId?: number;
  almacenId: number;
  retencionItbisId?: number;
  retencionIsrId?: number;
  productoNombre: string;
  cantidad: number;
  costo: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  itbisPor: number;
  retencionItbis: number;
  retencionIsr: number;
  almacenNombre: string;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxPFacturaServicio {
  id?: number;
  facturaId?: number;
  descripcion: string;
  monto: number;
  eliminar?: boolean;
}

export interface ICxPNotaDebito {
  id?: number;
  codigo?: number;
  companiaId: number;
  monedaId: number;
  suplidorId: number;
  solicitudId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  fecha: string;
  ncf?: string;
  ncfAfectado?: string;
  facturaAfectadaId?: number;
  observacion: string;
  tasa: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  isAjuste: boolean;
  isAnulado: boolean;
  isPagado: boolean;
  monedaNombre: string;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  suplidorNombre: string;
  suplidorRncCedula: string;
  suplidorDireccion?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  facturaNetoBien?: number;
  facturaNetoServicio?: number;
  facturaTipoBienServicioId?: number;
  facturaTipoBienServicioNombre?: string;
  facturaTipoBienServicioCodigo606?: number;
  solicitudCodigo?: number;
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPNotaCredito {
  id?: number;
  codigo?: number;
  companiaId: number;
  suplidorId: number;
  monedaId: number;
  monedaAplicarId: number;
  facturaAfectadaId?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  almacenId: number;
  fecha: string;
  ncf?: string;
  ncfVence?: string;
  ncfAfectado?: string;
  observacion: string;
  tasa: number;
  monto: number;
  montoItbis: number;
  total: number;
  totalTasa: number;
  totalOtraMoneda: number;
  pendiente: number;
  pendienteOtraMoneda: number;
  isAjuste: boolean;
  isAnulado: boolean;
  isDevolucion: boolean;
  suplidorNombre: string;
  suplidorRncCedula: string;
  suplidorDireccion?: string;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  monedaNombre: string;
  monedaAplicarNombre: string;
  facturaNetoBien?: number;
  facturaNetoServicio?: number;
  facturaTipoBienServicioId?: number;
  facturaTipoBienServicioNombre?: string;
  facturaTipoBienServicioCodigo606?: number;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  almacenNombre: string;
  almacenGrupoNombre: string;
  cuentas: ICuenta[];
  pagosFT: ICxPNotaCreditoPagoFT[];
  pagosND: ICxPNotaCreditoPagoND[];
  pagosRG: ICxPNotaCreditoPagoRG[];
  productos: ICxPNotaCreditoProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPNotaCreditoPagoFT {
  id?: number;
  notaCreditoId?: number;
  facturaId: number;
  pendiente?: number;
  montoPagado: number;
  pendienteDespues?: number;
  facturaCodigo: string;
  facturaFecha: string;
  facturaNcf: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface ICxPNotaCreditoPagoND {
  id?: number;
  notaCreditoId?: number;
  notaDebitoId: number;
  pendiente?: number;
  montoPagado: number;
  pendienteDespues?: number;
  notaDebitoCodigo: number;
  notaDebitoFecha: string;
  notaDebitoNcf?: string;
  monedaId: number;
  monedaNombre: string;
  tasa: number;
  eliminar?: boolean;
}

export interface ICxPNotaCreditoPagoRG {
  id?: number;
  notaCreditoId?: number;
  reembolsoId: number;
  pendiente?: number;
  montoPagado: number;
  pendienteDespues?: number;
  reembolsoCodigo: number;
  reembolsoFecha: string;
  monedaId: number;
  monedaNombre: string;
  eliminar?: boolean;
}
export interface ICxPNotaCreditoProducto {
  id?: number;
  notaCreditoId?: number;
  productoId: number;
  almacenId: number;
  productoNombre: string;
  cantidad: number;
  costo: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  itbisPor: number;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  eliminar?: boolean;
}

export interface ICxPOrdenCompra {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  monedaId: number;
  almacenId: number;
  requisicionId?: number;
  cotizacionNo?: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  suplidorId?: number;
  observacion?: string;
  bruto: number;
  descuento: number;
  neto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  isRecibido: boolean;
  isCerrado: boolean;
  isAnulado: boolean;
  monedaNombre: string;
  almacenNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  suplidorNombre: string;
  suplidorRncCedula: string;
  suplidorDireccion?: string;
  productos: ICxPOrdenCompraProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPOrdenCompraProducto {
  id?: number;
  ordenId?: number;
  productoId: number;
  almacenId: number;
  productoNombre: string;
  cantidad: number;
  cantidadRecibida: number;
  orden: number;
  costo: number;
  bruto: number;
  descuentoPor: number;
  descuento: number;
  neto: number;
  itbisPor: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  almacenNombre: string;
  productoReferencia: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  isServicio: boolean;
  monedaId?: number;
  suplidorId?: number;
  fecha?: string;
  eliminar?: boolean;
}

export interface ICxPPesada {
  id?: number;
  codigo?: number;
  codigoExterno?: number;
  codigoPesador?: number;
  companiaId: number;
  fecha: string;
  suplidorId: number;
  productoId: number;
  almacenId: number;
  cuentaId?: number;
  pesadorId?: number;
  buscadorId?: number;
  facturaId?: number;
  sacosLlenos: number;
  sacosVacios: number;
  sacosEntregados: number;
  sacosDevueltos: number;
  pesoBruto: number;
  pesoNeto: number;
  humedad: number;
  fanegaCantidad: number;
  fanegaPrecio: number;
  total: number;
  observacion?: string;
  isAnulado: boolean;
  suplidorNombre: string;
  productoNombre: string;
  almacenNombre: string;
  cuentaNombre?: string;
  cuenta?: string;
  pesadorNombre?: string;
  buscadorNombre?: string;
  facturaCodigo?: string;
  facturaNcf?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPPesador {
  id?: number;
  companiaId: number;
  nombre: string;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  comisionPorcentaje: number;
  isEmpleado: boolean;
  isInactivo: boolean;
  isSync: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPReembolso {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  monedaId: number;
  suplidorId: number;
  solicitudId?: number;
  observacion: string;
  netoServicio: number;
  netoBien: number;
  itbisFacturado: number;
  itbisCosto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  retencionItbis: number;
  retencionIsr: number;
  isPagado: boolean;
  isAnulado: boolean;
  monedaNombre: string;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  suplidorRncCedula: string;
  suplidorNombre: string;
  suplidorDireccion?: string;
  solicitudCodigo?: number;
  facturas: ICxPReembolsoFactura[];
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
  orden: number;
}

export interface ICxPReembolsoFactura {
  id?: number;
  codigo: string;
  reembolsoId?: number;
  tipoBienServicioId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  orden: number;
  fecha: string;
  ncf: string;
  ncfVence?: string;
  suplidorRncCedula: string;
  suplidorNombre: string;
  tasa: number;
  observacion?: string;
  netoServicio: number;
  netoBien: number;
  itbisFacturado: number;
  itbisCosto: number;
  itbis: number;
  selectivo: number;
  otrosImpuestos: number;
  propina: number;
  total: number;
  totalTasa: number;
  pendiente: number;
  retencionItbisId?: number;
  retencionItbis: number;
  retencionIsrId?: number;
  retencionIsr: number;
  cuentaId?: number;
  encfId?: string;
  qrCodeUrl?: string;
  codigoSeguridad?: string;
  firmaDigital?: string;
  isInformal: boolean;
  isGastoMenor: boolean;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  retencionItbisNombre?: string;
  retencionItbisPorcentaje?: number;
  retencionIsrNombre?: string;
  retencionIsrPorcentaje?: number;
  tipoBienServicioNombre: string;
  tipoBienServicioCodigo606: number;
  cuenta?: string;
  cuentaNombre?: string;
  eliminar?: boolean;
}

export interface ICxPSuplidor {
  id?: number;
  companiaId: number;
  nombre: string;
  rncCedula: string;
  condicionPago: number;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  grupoId: number;
  isInactivo: boolean;
  isGastoMenor: boolean;
  isInformal: boolean;
  isPagoExterior: boolean;
  eMail?: string;
  contacto?: string;
  isSync: boolean;
  sacoPeso: number;
  grupoNombre: string;
  grupoCuentaCxPId?: number;
  grupoCuentaCxP?: string;
  grupoCuentaCxPNombre?: string;
  balances: ICxPSuplidorBalance[];
  cuentasBanco: ICxPSuplidorCuentaBanco[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ICxPSuplidorBalance {
  id?: number;
  suplidorId?: number;
  monedaId: number;
  balance: number;
  monedaNombre: string;
}

export interface ICxPSuplidorCuentaBanco {
  id?: number;
  suplidorId?: number;
  cuenta: string;
  banco: string;
  tipo: number;
  monedaId: number;
  eliminar?: boolean;
}

export interface ICxPSuplidorGrupo {
  id?: number;
  companiaId: number;
  nombre: string;
  cuentaCxPId?: number;
  cuentaCxP?: string;
  cuentaCxPNombre?: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioAlmacen {
  id?: number;
  companiaId: number;
  grupoId: number;
  nombre: string;
  capacidad: number;
  isInactivo: boolean;
  grupoNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioAlmacenGrupo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioEntradaAlmacen {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion: string;
  isAnulado: boolean;
  almacenId: number;
  total: number;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  almacenNombre: string;
  almacenGrupoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  cuentas: ICuenta[];
  productos: IInventarioEntradaAlmacenProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioEntradaAlmacenProducto {
  id?: number;
  salidaAlmacenId?: number;
  productoId: number;
  cantidad: number;
  costo: number;
  total: number;
  almacenId: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProduccionEmpacador {
  id?: number;
  companiaId: number;
  nombre: string;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProduccionEmpacadorDia {
  id?: number;
  companiaId: number;
  fecha: string;
  empacadorId: number;
  empacadorNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProduccionFormula {
  id?: number;
  codigo?: number;
  companiaId: number;
  productoId: number;
  procedimiento?: string;
  isInactivo: boolean;
  isASeleccionar: boolean;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  productos: IInventarioProduccionFormulaProducto[];
  derivados: IInventarioProduccionFormulaDerivado[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProduccionFormulaDerivado {
  id?: number;
  formulaId: number;
  productoId: number;
  cantidad: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  productoCosto: number;
  eliminar?: boolean;
}

export interface IInventarioProduccionFormulaProducto {
  id?: number;
  formulaId: number;
  productoId: number;
  cantidad: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  productoCosto: number;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProduccion {
  id?: number;
  codigo?: number;
  companiaId: number;
  almacenId: number;
  fecha: string;
  observacion: string;
  porFinalizado: number;
  isFinalizado: boolean;
  isAnulado: boolean;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  almacenNombre: string;
  productos: IInventarioProduccionProducto[];
  materiasPrima: IInventarioProduccionMateriaPrima[];
  materiasPrimaSeleccion: IInventarioProduccionMateriaPrimaSeleccion[];
  derivados: IInventarioProduccionDerivado[];
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProduccionDerivado {
  id?: number;
  produccionId?: number;
  productoId: number;
  cantidad: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  eliminar?: boolean;
}

export interface IInventarioProduccionMateriaPrima {
  id?: number;
  produccionId?: number;
  productoId: number;
  almacenId: number;
  costo: number;
  cantidad: number;
  total: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  almacenNombre?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProduccionMateriaPrimaSeleccion {
  id?: number;
  produccionId?: number;
  formulaProductoId?: number;
  productoId: number;
  eliminar?: boolean;
}

export interface IInventarioProduccionProducto {
  id?: number;
  produccionId?: number;
  formulaId: number;
  productoId: number;
  cantidadOriginal: number;
  cantidad: number;
  cantidadProduccion: number;
  cantidadEmpacada: number;
  costoUnitario: number;
  total: number;
  isFinalizado: boolean;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  imagen?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  codigo: number;
  companiaId: number;
  isAnulado: boolean;
  produccionIsFinalizado: boolean;
  almacenId: number;
  almacenNombre: string;
  productosEmpacados: IInventarioProduccionProductoEmpaque[];
  inyecciones: IInventarioProduccionProductoInyeccion[];
  eliminar?: boolean;
}

export interface IInventarioProduccionProductoEmpaque {
  id?: number;
  fecha: string;
  produccionProductoId?: number;
  productoId: number;
  almacenId: number;
  cantidad: number;
  costo: number;
  total: number;
  isValidado: boolean;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  almacenNombre: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  imagen?: string;
  codigo: number;
  companiaId: number;
  derivados: IInventarioProduccionProductoEmpaqueDerivado[];
  eliminar?: boolean;
}

export interface IInventarioProduccionProductoEmpaqueDerivado {
  id?: number;
  produccionProductoEmpaqueId?: number;
  productoId: number;
  almacenId: number;
  cantidad: number;
  costo: number;
  total: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  almacenNombre: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProduccionProductoInyeccion {
  id?: number;
  produccionProductoId?: number;
  productoId: number;
  almacenId: number;
  costo: number;
  cantidad: number;
  total: number;
  equivalenciaNombre: string;
  equivalencia: number;
  cantidadEquivalencia: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  almacenNombre: string;
  produccionId: number;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioSalidaAlmacen {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion: string;
  isAnulado: boolean;
  almacenId: number;
  almacenNombre: string;
  almacenGrupoNombre: string;
  total: number;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  cuentas: ICuenta[];
  productos: IInventarioSalidaAlmacenProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioSalidaAlmacenProducto {
  id?: number;
  salidaAlmacenId: number;
  productoId: number;
  cantidad: number;
  costo: number;
  total: number;
  almacenId: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioConduceAlmacen {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion: string;
  isAnulado: boolean;
  almacenId: number;
  almacenNombre: string;
  almacenGrupoNombre: string;
  proyectoId?: number;
  total: number;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  proyectoCodigo?: string;
  proyectoNombre?: string;
  proyectoCuentaCostoId?: number;
  proyectoCuentaCosto?: string;
  proyectoCuentaCostoNombre?: string;
  ordenServicioProductoId?: number;
  cuentas: ICuenta[];
  productos: IInventarioSalidaAlmacenProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioConduceAlmacenProducto {
  id: number;
  conduceAlmacenId: number;
  productoId: number;
  cantidad: number;
  costo: number;
  total: number;
  almacenId: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  conduceCodigo: number;
  companiaId: number;
  fecha: string;
  proyectoId?: number;
  proyectoCodigo?: string;
  proyectoNombre?: string;
  almacenNombre?: string;
  eliminar?: boolean;
}

export interface IInventarioConfiguracion {
  id?: number;
  companiaId: number;
  almacenDestinoProduccionId?: number;
  almacenDestinoProduccionNombre?: string;
  tipoImpresionEntrada: number;
  tipoImpresionSalida: number;
  tamanoLetraEntrada: number;
  tamanoLetraSalida: number;
}

export interface IInventarioCorteInventario {
  id?: number;
  codigo?: number;
  companiaId: number;
  almacenId: number;
  fecha: string;
  observacion: string;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  total: number;
  isEjecutado: boolean;
  isAnulado: boolean;
  almacenNombre: string;
  almacenGrupoId: number;
  almacenGrupoNombre: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  cuentas: ICuenta[];
  productos: IInventarioCorteInventarioProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioCorteInventarioProducto {
  id?: number;
  corteInventarioId?: number;
  productoId: number;
  almacenId: number;
  costo: number;
  cantidadSistema: number;
  totalSistema: number;
  cantidadFisica: number;
  totalFisica: number;
  cantidad: number;
  total: number;
  isEjecutado: boolean;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioLiquidacionAduanal {
  id?: number;
  codigo?: number;
  companiaId: number;
  monedaId: number;
  almacenId: number;
  suplidorId: number;
  centroCostoId: number;
  centroCostoNombre: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
  fecha: string;
  fechaLlegada: string;
  tipo: number;
  tasa: number;
  colecturia?: string;
  acto?: number;
  planilla?: number;
  declaracion?: number;
  liquidacion?: number;
  conocimientoEmbarque?: string;
  detalle?: number;
  consolidado?: number;
  numeroBL?: string;
  manifiesto?: string;
  deposito?: number;
  documento?: string;
  regimen?: string;
  observacion: string;
  montoFOB: number;
  montoSeguro: number;
  montoFlete: number;
  montoOtros: number;
  montoCIF: number;
  montoGravamen: number;
  montoSelectivo: number;
  montoITBIS: number;
  montoGastos: number;
  montoArt52: number;
  montoMultas: number;
  montoLiquidacion: number;
  peso: number;
  isAnulado: boolean;
  isCerrado: boolean;
  almacenNombre: string;
  almacenGrupoNombre: string;
  suplidorNombre: string;
  suplidorRncCedula: string;
  monedaNombre: string;
  cuentas: ICuenta[];
  furgones: IInventarioLiquidacionAduanalFurgon[];
  gastos: IInventarioLiquidacionAduanalGasto[];
  productos: IInventarioLiquidacionAduanalProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioLiquidacionAduanalFurgon {
  id?: number;
  liquidacionId?: number;
  numero?: number;
  serial?: string;
  tipo?: string;
  cargaBultos?: number;
  pesoBruto?: number;
  eliminar?: boolean;
}

export interface IInventarioLiquidacionAduanalGasto {
  id?: number;
  liquidacionId?: number;
  tipoGastoId: number;
  monto: number;
  tipoGastoNombre: string;
  eliminar?: boolean;
}

export interface IInventarioLiquidacionAduanalProducto {
  id?: number;
  liquidacionId?: number;
  productoId: number;
  almacenId: number;
  isCerrado: boolean;
  cantidad: number;
  pesoUnitario: number;
  pesoTotal: number;
  montoFOBUnitario: number;
  montoFOB: number;
  montoSeguro: number;
  montoFlete: number;
  montoOtros: number;
  montoCIF: number;
  montoGravamen: number;
  montoSelectivo: number;
  montoGastos: number;
  montoArt52: number;
  montoMultas: number;
  porcientoLiberado: number;
  costeUnitario: number;
  montoITBIS: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  almacenNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProducto {
  id?: number;
  companiaId: number;
  grupoId: number;
  subGrupoId?: number;
  tipoITBISId: number;
  tipoCodigo?: string;
  referencia: string;
  nombre: string;
  unidadMedida?: string;
  pasillo?: string;
  gondola?: string;
  tramo?: string;
  imagen?: string;
  observacion?: string;
  costo: number;
  cantIdeal: number;
  cantMinima: number;
  gradosAlcohol: number;
  pesoUnitario: number;
  isPideSerial: boolean;
  isServicio: boolean;
  isInactivo: boolean;
  isSync: boolean;
  grupoNombre: string;
  isUsoInterno: boolean;
  tipoITBISNombre: string;
  tipoITBISPorcentaje: number;
  cuentaIngresoId: number;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventarioId: number;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCostoId: number;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  subGrupoNombre?: string;
  tipoInventarioId: number;
  tipoInventarioNombre: string;
  codigosBarra: IInventarioProductoCodigoBarra[];
  existencias: IInventarioProductoExistencia[];
  precios: IInventarioProductoPrecio[];
  unidadesMedida: IInventarioProductoUnidadMedida[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProductoCodigoBarra {
  id?: number;
  productoId?: number;
  codigoBarra: string;
  eliminar?: boolean;
}

export interface IInventarioProductoExistencia {
  companiaId: number;
  almacenId: number;
  almacenNombre: string;
  productoId: number;
  productoReferencia: string;
  productoNombre: string;
  tipoInventarioId: number;
  tipoInventarioNombre: string;
  grupoId: number;
  grupoNombre: string;
  subGrupoId?: number;
  subGrupoNombre?: string;
  existencia: number;
}

export interface IInventarioProductoGrupo {
  id?: number;
  companiaId: number;
  isInactivo: boolean;
  nombre: string;
  noFacturar: boolean;
  cuentaIngresoId: number;
  cuentaInventarioId: number;
  cuentaCostoId: number;
  tipoInventarioId: number;
  isUsoInterno: boolean;
  cuentaIngreso: string;
  cuentaIngresoNombre: string;
  cuentaInventario: string;
  cuentaInventarioNombre: string;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  tipoInventarioNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProductoHistorial {
  id: number;
  companiaId: number;
  documentoId: number;
  documentoCodigo: string;
  detalleId: number;
  tipo: string;
  fecha: string;
  cantidadEntrada: number;
  cantidadSalida: number;
  productoId: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  precio: number;
  costo: number;
  almacenOrigenId: number;
  almacenOrigenNombre: string;
  almacenDestinoId: number;
  almacenDestinoNombre: string;
  tipoInventarioId: number;
  tipoInventarioNombre: string;
  grupoId: number;
  grupoNombre: string;
  subGrupoId?: number;
  subGrupoNombre?: string;
  isServicio: boolean;
}

export interface IInventarioProductoHistorialExistencia {
  tipoInventarioNombre: string;
  grupoNombre: string;
  subGrupoNombre?: string;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  existencia: number;
  costo: number;
  total: number;
}

export interface IInventarioProductoHistorialMovimiento {
  almacenId: number;
  almacenNombre: string;
  tipoInventarioNombre: string;
  grupoNombre: string;
  subGrupoNombre?: string;
  productoId: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida?: string;
  documentoId: number;
  documentoCodigo: number;
  tipo: string;
  fecha: string;
  cantidadEntrada: number;
  cantidadSalida: number;
  costo: number;
}

export interface IInventarioProductoPrecio {
  id?: number;
  productoId?: number;
  nombre: string;
  precio: number;
  porBeneficio: number;
  monedaId: number;
  grupo: number;
  unidadMedidaId: number;
  cantidadDesde?: number;
  cantidadHasta?: number;
  monedaNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProductoSubGrupo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  grupoId: number;
  grupoNombre: string;
  tipoInventarioId: number;
  tipoInventarioNombre: string;
  eliminar?: boolean;
}

export interface IInventarioProductoTipoInventario {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioProductoUnidadMedida {
  id?: number;
  productoId: number;
  nombre: string;
  equivalencia: number;
  eliminar?: boolean;
}

export interface IInventarioProyecto {
  id?: number;
  companiaId: number;
  codigo?: number;
  fecha: string;
  nombre: string;
  ubicacion: string;
  cuentaCostoId: number;
  isInactivo: boolean;
  cuentaCosto: string;
  cuentaCostoNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioTransferenciaAlmacen {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion: string;
  isAnulado: boolean;
  almacenOrigenId: number;
  almacenDestinoId: number;
  almacenOrigenNombre: string;
  almacenDestinoNombre: string;
  productos: IInventarioTransferenciaAlmacenProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioTransferenciaAlmacenProducto {
  id?: number;
  transferenciaAlmacenId?: number;
  productoId: number;
  cantidad: number;
  almacenOrigenId?: number;
  almacenDestinoId?: number;
  almacenOrigenNombre?: string;
  almacenDestinoNombre?: string;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida: string;
  eliminar?: boolean;
}

export interface IInventarioLiquidacionAduanalTipoGasto {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioRequisicion {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  observacion: string;
  isAnulado: boolean;
  isAutorizado: boolean;
  isRechazado: boolean;
  creadoPor: string;
  autorizadoPor?: string;
  rechazadoPor?: string;
  ordenId?: number;
  ordenCodigo?: number;
  productos: IInventarioRequisicionProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IInventarioRequisicionProducto {
  id?: number;
  requisicionId: number;
  productoId: number;
  cantidad: number;
  productoReferencia: string;
  productoNombre: string;
  productoUnidadMedida: string;
  eliminar?: boolean;
}

export interface IModulo {
  id: number;
  nombre: string;
  icono: string;
  accesos: IModuloAcceso[];
}

export interface IModuloAcceso {
  id: number;
  moduloId: number;
  nombre: string;
  link?: string;
  icono?: string;
  orden?: number;
  nivel: number;
  moduloNombre: string;
  moduloIcono: string;
}

export interface INomina {
  id?: number;
  codigo?: number;
  companiaId: number;
  tipoNomina: number;
  fechaDesde: string;
  fechaHasta: string;
  quincena: number;
  total: number;
  isCerrado: boolean;
  detalles: INominaDetalle[];
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaConfiguracion {
  id: number;
  companiaId: number;
  codigoNomina: number;
  riesgoLaboral: number;
  tipoNomina: number;
  tipoCalculoIRS: number;
  tipoCalculoAFP: number;
  tipoCalculoARS: number;
  diasTrabajoMes: number;
  bancoChequeId?: number;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaDepartamento {
  id?: number;
  companiaId: number;
  centroCostoId: number;
  centroCostoProyectoId?: number;
  centroCostoSubProyectoId?: number;
  nombre: string;
  cuentaSueldoNetoId?: number;
  cuentaSueldoBrutoId?: number;
  cuentaISRId?: number;
  cuentaAFPEmpleadoId?: number;
  cuentaAFPEmpresaPasivoId?: number;
  cuentaAFPEmpresaGastoId?: number;
  cuentaARSEmpleadoId?: number;
  cuentaARSEmpresaPasivoId?: number;
  cuentaARSEmpresaGastoId?: number;
  cuentaRiesgoLaboralPasivoId?: number;
  cuentaRiesgoLaboralGastoId?: number;
  cuentaInfotepPasivoId?: number;
  cuentaInfotepGastoId?: number;
  cuentaRegaliaPasivoId?: number;
  cuentaRegaliaGastoId?: number;
  isInactivo: boolean;
  cuentaSueldoNeto?: string;
  cuentaSueldoNetoNombre?: string;
  cuentaSueldoBruto?: string;
  cuentaSueldoBrutoNombre?: string;
  cuentaISR?: string;
  cuentaISRNombre?: string;
  cuentaAFPEmpleado?: string;
  cuentaAFPEmpleadoNombre?: string;
  cuentaAFPEmpresaPasivo?: string;
  cuentaAFPEmpresaPasivoNombre?: string;
  cuentaAFPEmpresaGasto?: string;
  cuentaAFPEmpresaGastoNombre?: string;
  cuentaARSEmpleado?: string;
  cuentaARSEmpleadoNombre?: string;
  cuentaARSEmpresaPasivo?: string;
  cuentaARSEmpresaPasivoNombre?: string;
  cuentaARSEmpresaGasto?: string;
  cuentaARSEmpresaGastoNombre?: string;
  cuentaRiesgoLaboralPasivo?: string;
  cuentaRiesgoLaboralPasivoNombre?: string;
  cuentaRiesgoLaboralGasto?: string;
  cuentaRiesgoLaboralGastoNombre?: string;
  cuentaInfotepPasivo?: string;
  cuentaInfotepPasivoNombre?: string;
  cuentaInfotepGasto?: string;
  cuentaInfotepGastoNombre?: string;
  cuentaRegaliaPasivo?: string;
  cuentaRegaliaPasivoNombre?: string;
  cuentaRegaliaGasto?: string;
  cuentaRegaliaGastoNombre?: string;
  centroCostoNombre: string;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoNombre?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaDetalle {
  id?: number;
  nominaId?: number;
  empleadoId: number;
  empleadoDepartamentoId: number;
  tipoPago: number;
  chequeId?: number;
  montoBruto: number;
  otrosPagos: number;
  totalPagos: number;
  otrosDescuentos: number;
  AFPEmpleado: number;
  ARSEmpleado: number;
  ISREmpleado: number;
  totalDescuentos: number;
  totalEmpleado: number;
  infotep: number;
  riesgoLaboral: number;
  AFPEmpresa: number;
  ARSEmpresa: number;
  totalPatrono: number;
  empleadoNombre: string;
  empleadoCedula?: string;
  empleadoPasaporte?: string;
  empleadoCuentaCobrarId?: number;
  empleadoCuentaCobrar?: string;
  empleadoCuentaCobrarNombre?: string;
  chequeCodigo?: number;
  empleadoDepartamentoNombre: string;
  empleadoTipoPago: number;
  empleadoBancoCuenta?: string;
  empleadoBancoTipoCuenta: number;
  empleadoEMail: string;
  empleadoPosicion?: string;
  sueldoMensual: number;
  novedades: INominaDetalleNovedad[];
  prestamoPagos: INominaPrestamoPago[];
  departamento: INominaDepartamento;
  cheque: IBancoCheque;
  eliminar?: boolean;
}

export interface INominaDetalleNovedad {
  id?: number;
  nominaDetalleId?: number;
  novedadId: number;
  monto: number;
  novedadNombre: string;
  calculo: string;
  cuentaId?: number;
  cuenta?: string;
  cuentaNombre?: string;
  isAplicaAFP: boolean;
  isAplicaARS: boolean;
  isAplicaISR: boolean;
  isAplicaRiesgoLaboral: boolean;
  isAplicaInfotep: boolean;
  isAplicaRegalia: boolean;
  eliminar?: boolean;
}

export interface INominaDetalleReporte {
  id: number;
  nominaId: number;
  empleadoId: number;
  empleadoDepartamentoId: number;
  tipoPago: number;
  chequeId?: number;
  montoBruto: number;
  otrosPagos: number;
  totalPagos: number;
  otrosDescuentos: number;
  AFPEmpleado: number;
  ARSEmpleado: number;
  ISREmpleado: number;
  totalDescuentos: number;
  totalEmpleado: number;
  infotep: number;
  riesgoLaboral: number;
  AFPEmpresa: number;
  ARSEmpresa: number;
  totalPatrono: number;
  codigo: number;
  companiaId: number;
  tipoNomina: number;
  fechaDesde: string;
  fechaHasta: string;
  quincena: number;
  totalNomina: number;
  isCerrado: boolean;
  empleadoNombre: string;
  empleadoCedula?: string;
  empleadoFechaEntrada: string;
  empleadoPasaporte?: string;
  empleadoCuentaCobrarId?: number;
  empleadoCuentaCobrar?: string;
  empleadoCuentaCobrarNombre?: string;
  chequeCodigo?: number;
  empleadoDepartamentoNombre: string;
  empleadoTipoPago: number;
  empleadoBancoCuenta?: string;
  empleadoBancoTipoCuenta?: number;
  empleadoEMail?: string;
  empleadoPosicion?: string;
}

export interface INominaDetalleReporteResumido {
  empleadoId: number;
  empleadoDepartamentoId: number;
  montoBruto: number;
  otrosPagos: number;
  totalPagos: number;
  otrosDescuentos: number;
  AFPEmpleado: number;
  ARSEmpleado: number;
  ISREmpleado: number;
  totalDescuentos: number;
  totalEmpleado: number;
  infotep: number;
  riesgoLaboral: number;
  AFPEmpresa: number;
  ARSEmpresa: number;
  totalPatrono: number;
  companiaId: number;
  totalNomina: number;
  empleadoNombre: string;
  empleadoCedula?: string;
  empleadoFechaEntrada: string;
  empleadoPasaporte?: string;
  empleadoCuentaCobrarId?: number;
  empleadoCuentaCobrar?: string;
  empleadoCuentaCobrarNombre?: string;
  empleadoDepartamentoNombre: string;
  empleadoBancoCuenta?: string;
  empleadoBancoTipoCuenta?: number;
  empleadoEMail?: string;
  empleadoPosicion?: string;
}

export interface INominaEmpleado {
  id?: number;
  companiaId: number;
  departamentoId: number;
  cuentaCobrarId?: number;
  supAFPId?: number;
  supARSId?: number;
  posicionId?: number;
  grupoSanguineoId?: number;
  tipoPago: number;
  bancoTipoCuenta: number;
  viveCon?: number;
  viveEnCasa?: number;
  tipoNomina: number;
  anosUniversidad?: number;
  sueldoMensual: number;
  nombre: string;
  nombre2?: string;
  apellido1: string;
  apellido2?: string;
  cedula?: string;
  pasaporte?: string;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  estadoCivil?: string;
  sexo?: string;
  eMail?: string;
  foto?: string;
  bancoCuenta?: string;
  bancoNombre?: string;
  nss?: string;
  lugarNacimiento?: string;
  nacionalidad?: string;
  ciudadPrimaria?: string;
  institucionPrimaria?: string;
  ciudadBachiller?: string;
  institucionBachiller?: string;
  ciudadUniversidad?: string;
  institucionUniversidad?: string;
  tituloUniversidad?: string;
  fechaNacimiento?: string;
  fechaEntrada: string;
  fechaSalida?: string;
  fechaInicioPrimaria?: string;
  fechaFinPrimaria?: string;
  fechaInicioBachiller?: string;
  fechaFinBachiller?: string;
  fechaInicioUniversidad?: string;
  fechaFinUniversidad?: string;
  isConductor?: boolean;
  isExtranjero: boolean;
  isCancelado: boolean;
  nombreCompleto: string;
  departamentoNombre: string;
  cuentaCobrarCuenta?: string;
  cuentaCobrarNombre?: string;
  supAFPNombre?: string;
  supARSNombre?: string;
  posicionNombre?: string;
  grupoSanguineoNombre?: string;
  cursos: INominaEmpleadoCurso[];
  experiencias: INominaEmpleadoExperienciaLaboral[];
  habilidades: INominaEmpleadoHabilidad[];
  idiomas: INominaEmpleadoIdioma[];
  referencias: INominaEmpleadoReferencia[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaEmpleadoCurso {
  id?: number;
  empleadoId?: number;
  fechaInicio: string;
  fechaFin: string;
  ciudad: string;
  institucion: string;
  titulo: string;
  anos: number;
  eliminar?: boolean;
}

export interface INominaEmpleadoExperienciaLaboral {
  id?: number;
  empleadoId?: number;
  cargo: string;
  empresa: string;
  direccion: string;
  ciudad: string;
  telefono: string;
  anoInicio: string;
  anoFin: string;
  motivo: string;
  eliminar?: boolean;
}

export interface INominaEmpleadoHabilidad {
  id?: number;
  empleadoId?: number;
  nombre: string;
  eliminar?: boolean;
}

export interface INominaEmpleadoIdioma {
  id?: number;
  empleadoId?: number;
  nombre: string;
  lee: boolean;
  escribe: boolean;
  habla: boolean;
  eliminar?: boolean;
}

export interface INominaEmpleadoNovedadFijo {
  id?: number;
  companiaId: number;
  empleadoId: number;
  novedadId: number;
  quincena: number;
  monto: number;
  novedadNombre: string;
  calculo: string;
  cuentaId?: number;
  cuenta?: string;
  cuentaNombre?: string;
  isAplicaAFP: boolean;
  isAplicaARS: boolean;
  isAplicaISR: boolean;
  isAplicaRiesgoLaboral: boolean;
  isAplicaInfotep: boolean;
  isAplicaRegalia: boolean;
  empleadoNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaEmpleadoNovedadVariable {
  id?: number;
  companiaId: number;
  fecha: string;
  empleadoId: number;
  novedadId: number;
  tipoNomina: number;
  monto: number;
  novedadNombre: string;
  calculo: string;
  cuentaId?: number;
  cuenta?: string;
  cuentaNombre?: string;
  isAplicaAFP: boolean;
  isAplicaARS: boolean;
  isAplicaISR: boolean;
  isAplicaRiesgoLaboral: boolean;
  isAplicaInfotep: boolean;
  isAplicaRegalia: boolean;
  empleadoNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaEmpleadoReferencia {
  id?: number;
  empleadoId?: number;
  nombre: string;
  empresa?: string;
  direccion?: string;
  relacion?: string;
  ocupacion?: string;
  cargo?: string;
  telefono?: string;
  eliminar?: boolean;
}

export interface INominaEmpleadoSueldoPorNomina {
  id?: number;
  codigo?: number;
  companiaId: number;
  tipoNomina: number;
  fechaDesde: string;
  fechaHasta: string;
  total: number;
  detalles: INominaEmpleadoSueldoPorNominaDetalle[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaEmpleadoSueldoPorNominaDetalle {
  id?: number;
  companiaId?: number;
  sueldoPorNominaId?: number;
  empleadoId: number;
  sueldo: number;
  sueldoMensual: number;
  empleadoNombre: string;
  empleadoDepartamentoNombre: string;
  eliminar?: boolean;
}

export interface INominaPrestamo {
  id?: number;
  codigo?: number;
  companiaId: number;
  empleadoId: number;
  montoPrestamo: number;
  montoCuota: number;
  montoPagado: number;
  montoPendiente: number;
  fechaInicio: string;
  fechaFin: string;
  cuotas: number;
  cuotasPagadas: number;
  isPagado: boolean;
  isInactivo: boolean;
  empleadoNombre: string;
  empleadoCedula: string;
  empleadoPasaporte: string;
  empleadoDepartamentoNombre: string;
  empleadoCuentaCobrarId?: number;
  pagos: INominaPrestamoPago[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INominaPrestamoPago {
  id?: number;
  nominaId?: number;
  nominaDetalleId?: number;
  prestamoId?: number;
  monto: number;
  nominaCodigo?: number;
  nominaFechaDesde?: string;
  nominaFechaHasta?: string;
  prestamoCodigo?: number;
  eliminar?: boolean;
}

export interface INominaNovedad {
  id?: number;
  companiaId: number;
  nombre: string;
  calculo: string;
  cuentaId?: number;
  isAplicaAFP: boolean;
  isAplicaARS: boolean;
  isAplicaISR: boolean;
  isAplicaRiesgoLaboral: boolean;
  isAplicaInfotep: boolean;
  isAplicaRegalia: boolean;
  isInactivo: boolean;
  cuentaNombre?: string;
  cuenta?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface INotificacion {
  id: number;
  mensaje: string;
  idUsuario: number;
  desde: string;
  companiaId: number;
  actualizarLoginState: boolean;
  actualizarCache: boolean;
}

export interface IOrdenServicioEstado {
  id?: number;
  companiaId: number;
  nombre: string;
  color?: string;
  numEvento: number;
  isInactivo: boolean;
  numEventoNombre: string;
  tecnicos: IOrdenServicioEstadoTecnico[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IOrdenServicioEstadoTecnico {
  id?: number;
  estadoId?: number;
  tecnicoId: number;
  estadoNombre?: string;
  tecnicoNombre: string;
  tecnicoEmail: string;
  companiaId: number;
  eliminar?: boolean;
}

export interface IOrdenServicioOrden {
  id?: number;
  codigo?: number;
  companiaId: number;
  fecha: string;
  fechaCompromiso: string;
  clienteId: number;
  localidadId: number;
  tipoOrdenId: number;
  tipoOrdenIsFacturar?: number;
  estadoId: number;
  observacion: string;
  observacionCierre?: string;
  facturaId?: number;
  proformaId?: number;
  creadoPorId: number;
  isCerrado: boolean;
  isAnulado: boolean;
  clienteNombre: string;
  clienteRncCedula: string;
  clienteTelefono1?: string;
  localidadNombre: string;
  tipoOrdenNombre: string;
  estadoNombre: string;
  estadoColor?: string;
  estadoNumEvento: number;
  facturaCodigo?: number;
  proformaCodigo?: number;
  conduceCodigo?: number;
  creadoPorNombre?: string;
  eventos: IOrdenServicioOrdenEvento[];
  productos: IOrdenServicioOrdenProducto[];
  tecnicos: IOrdenServicioOrdenTecnico[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IOrdenServicioOrdenEvento {
  id?: number;
  companiaId?: number;
  ordenId?: number;
  usuarioId: number;
  estadoId: number;
  fecha: string;
  accionRealizada: string;
  observacion?: string;
  ordenCodigo: number;
  ordenClienteRncCedula: string;
  ordenClienteNombre: string;
  ordenLocalidadNombre: string;
  ordenObservacion: string;
  ordenObservacionCierre?: string;
  usuarioUsername: string;
  estadoNombre: string;
  estadoColor?: string;
  estadoNumEventoNombre?: string;
  eliminar?: boolean;
}

export interface IOrdenServicioOrdenProducto {
  id?: number;
  ordenId?: number;
  productoId: number;
  almacenId: number;
  conduceId?: number;
  cantidad: number;
  productoReferencia: string;
  productoNombre: string;
  almacenNombre: string;
  almacenGrupoNombre?: string;
  conduceNombre?: string;
  eliminar?: boolean;
}

export interface IOrdenServicioOrdenTecnico {
  id?: number;
  ordenId?: number;
  tecnicoId: number;
  creadoPorId: number;
  tecnicoNombre: string;
  tecnicoEmail: string;
  companiaId: number;
  almacenId?: number;
  almacenNombre?: string;
  eliminar?: boolean;
}

export interface IOrdenServicioTecnicoLogin {
  id: number;
  nombre: string;
  email: string;
  token: string;
  companiaId: number;
  almacenId: number;
}

export interface IOrdenServicioTecnico {
  id?: number;
  companiaId: number;
  almacenId: number;
  nombre: string;
  rncCedula: string;
  email: string;
  posicion?: string;
  isNotificarConduce: boolean;
  isInactivo: boolean;
  almacenNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IOrdenServicioTipo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  isFacturar: boolean;
  isGarantia: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IRRHHAccion {
  id?: number;
  codigo?: number;
  companiaId: number;
  accionId: number;
  empleadoId: number;
  fechaEfectiva: string;
  observacion: string;
  sueldoActual?: number;
  sueldoPorcentaje?: number;
  sueldoSumar?: number;
  sueldoNuevo?: number;
  posicionActualId?: number;
  posicionNuevoId?: number;
  departamentoActualId?: number;
  departamentoNuevoId?: number;
  licenciaDias?: number;
  licenciaFechaInicio?: string;
  licenciaFechaFin?: string;
  licenciaIsDisfruteSueldo: boolean;
  salidaFecha?: string;
  salidaIsDisfruteSueldo: boolean;
  clasificacionNombre: string;
  accionNombre: string;
  empleadoIdentificacion?: string;
  empleadoNombre: string;
  empleadoDepartamentoNombre: string;
  posicionActualNombre?: string;
  posicionNuevoNombre?: string;
  departamentoActualNombre?: string;
  departamentoNuevoNombre?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IRRHHNominaRegalia {
  id?: number;
  codigo?: number;
  companiaId: number;
  ano: number;
  observacion: string;
  total: number;
  isAnulado: boolean;
  detalles: IRRHHNominaRegaliaDetalle[];
  cuentas: ICuenta[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IRRHHNominaRegaliaDetalle {
  id?: number;
  nominaId?: number;
  empleadoId: number;
  enero: number;
  febrero: number;
  marzo: number;
  abril: number;
  mayo: number;
  junio: number;
  julio: number;
  agosto: number;
  septiembre: number;
  octubre: number;
  noviembre: number;
  diciembre: number;
  total: number;
  empleadoNombre: string;
  empleadoCedula?: string;
  empleadoPasaporte?: string;
  empleadoCuentaCobrarId?: number;
  empleadoCuentaCobrar?: string;
  empleadoCuentaCobrarNombre?: string;
  empleadoDepartamentoId: number;
  empleadoDepartamentoNombre: string;
  empleadoTipoPago: number;
  empleadoBancoCuenta?: string;
  empleadoBancoTipoCuenta: number;
  empleadoEMail?: string;
  empleadoPosicion?: string;
  departamento: INominaDepartamento;
  eliminar?: boolean;
}

export interface IRRHHSolicitudEmpleo {
  id?: number;
  codigo?: number;
  companiaId: number;
  supAFPId?: number;
  supARSId?: number;
  posicionId?: number;
  grupoSanguineoId?: number;
  viveCon?: number;
  viveEnCasa?: number;
  anosUniversidad?: number;
  nombre: string;
  nombre2?: string;
  apellido1: string;
  apellido2?: string;
  cedula?: string;
  pasaporte?: string;
  direccion?: string;
  telefono1?: string;
  telefono2?: string;
  telefono3?: string;
  telefono4?: string;
  estadoCivil?: string;
  sexo?: string;
  eMail?: string;
  foto?: string;
  nss?: string;
  lugarNacimiento?: string;
  nacionalidad?: string;
  ciudadPrimaria?: string;
  institucionPrimaria?: string;
  ciudadBachiller?: string;
  institucionBachiller?: string;
  ciudadUniversidad?: string;
  institucionUniversidad?: string;
  tituloUniversidad?: string;
  fecha: string;
  fechaNacimiento?: string;
  fechaInicioPrimaria?: string;
  fechaFinPrimaria?: string;
  fechaInicioBachiller?: string;
  fechaFinBachiller?: string;
  fechaInicioUniversidad?: string;
  fechaFinUniversidad?: string;
  isConductor?: boolean;
  isExtranjero: boolean;
  isAnulado: boolean;
  supAFPNombre?: string;
  supARSNombre?: string;
  posicionNombre?: string;
  grupoSanguineoNombre?: string;
  cursos: IRRHHSolicitudEmpleoCurso[];
  experiencias: IRRHHSolicitudEmpleoExperienciaLaboral[];
  habilidades: IRRHHSolicitudEmpleoHabilidad[];
  idiomas: IRRHHSolicitudEmpleoIdioma[];
  referencias: IRRHHSolicitudEmpleoReferencia[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IRRHHSolicitudEmpleoCurso {
  id?: number;
  solicitudId?: number;
  fechaInicio: string;
  fechaFin: string;
  ciudad: string;
  institucion: string;
  titulo: string;
  anos: number;
  eliminar?: boolean;
}

export interface IRRHHSolicitudEmpleoExperienciaLaboral {
  id?: number;
  solicitudId?: number;
  cargo: string;
  empresa: string;
  direccion: string;
  ciudad: string;
  telefono: string;
  anoInicio: string;
  anoFin: string;
  motivo: string;
  eliminar?: boolean;
}

export interface IRRHHSolicitudEmpleoHabilidad {
  id?: number;
  solicitudId?: number;
  nombre: string;
  eliminar?: boolean;
}

export interface IRRHHSolicitudEmpleoIdioma {
  id?: number;
  solicitudId?: number;
  nombre: string;
  lee: boolean;
  escribe: boolean;
  habla: boolean;
  eliminar?: boolean;
}

export interface IRRHHSolicitudEmpleoReferencia {
  id?: number;
  solicitudId?: number;
  nombre: string;
  empresa?: string;
  direccion?: string;
  relacion?: string;
  ocupacion?: string;
  cargo?: string;
  telefono?: string;
  eliminar?: boolean;
}

export interface IRRHHPosicion {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IRNC {
  rnc: string;
  nombre: string;
  nombreFiscal: string;
  razon: string;
  fecha: string;
  isActivo: boolean;
  estado: string;
}

export interface ITallerMecanicaArea {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaAreaEstadosServicio {
  id?: number;
  companiaId: number;
  areaId?: number;
  nombre: string;
  orden: number;
  isInactivo: boolean;
  areaNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaConfiguracion {
  id?: number;
  companiaId: number;
  almacenId: number;
  mantenimientoProductoId?: number;
  mantenimientoDias: number;
  mantenimientoConsultaDefaultDias: number;
  mantenimientoAvisoClienteDias: number;
  mantenimientoAvisoClienteHora: number;
  mantenimientoAvisoClienteActivo: boolean;
  mantenimientoAvisoClienteHeader: string;
  mantenimientoAvisoClienteFooter: string;
  almacenNombre: string;
  almacenGrupoNombre: string;
  mantenimientoProductoNombre?: string;
  mantenimientoProductoReferencia?: string;
  mantenimientoProductoUnidadMedida?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaMecanico {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaOrdenTipo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaOrden {
  id?: number;
  companiaId: number;
  codigo?: number;
  tipoOrdenId: number;
  areaId: number;
  vehiculoId: number;
  fecha: string;
  fechaCompromiso: string;
  fechaEntrega?: string;
  estadoId: number;
  kilometros?: number;
  observacion?: string;
  isCerrado: boolean;
  tipoOrdenNombre: string;
  areaNombre: string;
  vehiculoPlaca: string;
  vehiculoMarca: string;
  vehiculoModelo: string;
  vehiculoColor: string;
  vehiculoAno: number;
  vehiculoAsignado?: string;
  clienteId: number;
  clienteRncCedula: string;
  clienteNombre: string;
  clienteTelefono1?: string;
  estadoNombre: string;
  estadoOrden: number;
  mecanicos: ITallerMecanicaOrdenMecanico[];
  productos: ITallerMecanicaOrdenProducto[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaOrdenEstadosLog {
  id: number;
  ordenId: number;
  estadoId: number;
  fechaHora: string;
  tiempoMinutos: number;
  horas: number;
  minutos: number;
  estadoNombre: string;
  areaId: number;
  areaNombre: string;
  companiaId: number;
  codigo: number;
  tipoOrdenId: number;
  tipoOrdenNombre: string;
  vehiculoId: number;
  vehiculoPlaca: string;
  vehiculoMarca: string;
  vehiculoModelo: string;
  vehiculoColor: string;
  vehiculoAno: number;
  clienteNombre: string;
  fecha: string;
  fechaCompromiso: string;
  fechaEntrega: string;
  kilometros: number;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface ITallerMecanicaMantenimientos {
  ordenId: number;
  clienteId: number;
  clienteNombre: string;
  clienteTelefono1?: string;
  vehiculoId: number;
  vehiculoPlaca: string;
  vehiculoMarca: string;
  vehiculoModelo: string;
  vehiculoColor: string;
  vehiculoAno: number;
  vehiculoAsignado?: string;
  fechaMantenimiento: string;
}

export interface ITallerMecanicaOrdenMecanico {
  id?: number;
  ordenId?: number;
  mecanicoId: number;
  mecanicoNombre: string;
  eliminar?: boolean;
}

export interface ITallerMecanicaOrdenProducto {
  id?: number;
  ordenId?: number;
  productoId: number;
  almacenId: number;
  cantidad: number;
  productoNombre: string;
  productoReferencia: string;
  productoUnidadMedida?: string;
  almacenNombre: string;
  eliminar?: boolean;
}

export interface ITallerMecanicaVehiculo {
  id?: number;
  companiaId: number;
  clienteId: number;
  placa: string;
  marca: string;
  modelo: string;
  color: string;
  ano: number;
  vin?: string;
  asignado?: string;
  isInactivo: boolean;
  clienteNombre: string;
  clienteRncCedula: string;
  clienteTelefono1?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IUsuario {
  id: number;
  realm?: string;
  username?: string;
  password?: string;
  email: string;
  emailVerified?: boolean;
  verificationToken?: string;
  imagen?: string;
  avatar?: string;
  roleId?: number;
  roleNombre?: string;
  almacenGrupoId: number;
  almacenGrupoNombre?: string;
  utilizaCuadreCaja: boolean;
}

export interface IUsuarioRole {
  id: number;
  principalType: string;
  principalId: number;
  roleId: number;
  companiaId: number;
  roleNombre: string;
}

export interface IUsuarioAcceso {
  id: number;
  usuarioId: number;
  accesoId: number;
  companiaId: number;
  accesoNombre: string;
  accesoLink?: string;
  accesoIcono?: string;
  accesoOrden?: number;
  moduloId: number;
  moduloNombre: string;
  moduloIcono?: string;
}

export interface IUsuarioCompania {
  id: number;
  companiaId: number;
  usuarioId: number;
  companiaNombre: string;
  companiaIcono: string;
  companiaLogo: string;
  companiaPropinaPor: number;
  companiaSelectivoPor: number;
  rnc?: string;
  telefono?: string;
  direccion?: string;
  eMail?: string;
  utilizaCentroCosto: boolean;
  cantidadUsuarios: number;
  r: number;
  g: number;
  b: number;
  a: number;
  usuarioRealm?: string;
  usuarioUsername?: string;
  usuarioEmail: string;
  usuarioAvatar?: string;
  usuarioImagen?: string;
  utilizaCuadreCaja: boolean;
  almacenGrupoId: number;
  almacenGrupoNombre?: string;
  cuentaCxCItbisId?: number;
  cuentaCxCItbis?: string;
  cuentaCxCItbisNombre?: string;
  cuentaCxPItbisId?: number;
  cuentaCxPItbis?: string;
  cuentaCxPItbisNombre?: string;
  cuentaDescuentoId?: number;
  cuentaDescuento?: string;
  cuentaDescuentoNombre?: string;
  cuentaPropinaId?: number;
  cuentaPropina?: string;
  cuentaPropinaNombre?: string;
  cuentaSelectivoId?: number;
  cuentaSelectivo?: string;
  cuentaSelectivoNombre?: string;
  cuentaOtrosImpuestosId?: number;
  cuentaOtrosImpuestos?: string;
  cuentaOtrosImpuestosNombre?: string;
  cuentaIngresoNotaDebitoClienteId?: number;
  cuentaIngresoNotaDebitoCliente?: string;
  cuentaIngresoNotaDebitoClienteNombre?: string;
  cuentaCxCRetItbisId?: number;
  cuentaCxCRetItbis?: string;
  cuentaCxCRetItbisNombre?: string;
  cuentaCxCRetIsrId?: number;
  cuentaCxCRetIsr?: string;
  cuentaCxCRetIsrNombre?: string;
  cuentaCxPRetItbisId?: number;
  cuentaCxPRetItbis?: string;
  cuentaCxPRetItbisNombre?: string;
  cuentaCxPRetIsrId?: number;
  cuentaCxPRetIsr?: string;
  cuentaCxPRetIsrNombre?: string;
  cuentaCreditoNDSuplidorId?: number;
  cuentaCreditoNDSuplidor?: string;
  cuentaCreditoNDSuplidorNombre?: string;
  cuentaDebitoNCClienteId?: number;
  cuentaDebitoNCCliente?: string;
  cuentaDebitoNCClienteNombre?: string;
}

export interface IVisitaArea {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  destinos: IVisitaDestino[];
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaDestino {
  id?: number;
  areaId: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  areaNombre: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaConfiguracion {
  id?: number;
  companiaId: number;
  isImprimir: boolean;
  tipoPapel: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaVisitanteEmpresa {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaVisitanteTipo {
  id?: number;
  companiaId: number;
  nombre: string;
  isInactivo: boolean;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaVisitante {
  id?: number;
  companiaId: number;
  empresaId: number;
  tipoId: number;
  rncCedula: string;
  nombre: string;
  isBloqueado: boolean;
  isDentro: boolean;
  empresaNombre: string;
  tipoNombre: string;
  cedulaFrente?: string;
  cedulaAtras?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}

export interface IVisitaVisita {
  id?: number;
  codigo?: number;
  companiaId: number;
  visitanteId: number;
  destinoId: number;
  fechaEntrada: string;
  fechaSalida: string;
  isFinalizada: boolean;
  tiempoHoras: number;
  tiempoMinutos: number;
  visitanteRncCedula: string;
  visitanteNombre: string;
  empresaId: number;
  empresaNombre: string;
  tipoId: number;
  tipoNombre: string;
  destinoNombre: string;
  areaId: number;
  areaNombre: string;
  cedulaFrente?: string;
  cedulaAtras?: string;
  evento?: IAGlobalEvento;
  tableData?: any;
}
